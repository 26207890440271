import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Layout from "../../components/global/Layout";
import Header from "../../components/reusable/Header";
import { mockDataBivabBlog } from "../../data/mockData";
import { DELETENETWORK, GETNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";
const Team = () => {
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    designation: "",
    image: "",
  });
  const [data, setData] = useState("");

  const [name, setName] = useState("");
  const [designation, setDesignation] = useState("");
  const [image, setImage] = useState("");

  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [editdesigation, setEditedDesigation] = useState("");
  const [editimage, setEditImage] = useState(null);

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setFormData({
      id: "",
      name: "",
      designation: "",
      image: "",
    });
  };

  const deleteData = async (id) => {
    const responses = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_TEAM_DELETE}${id}`
    );
    if (responses.status) {
      fetchData();
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_TEAM_GETALL);
    setData(response.data);
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    getItemByid(id);
    setIsEditModalOpen(true);
  };
  const getItemByid = async (id) => {
    const response = await GETNETWORK(`${ApiUrl.BIVABDEV_TEAM_GETBYID}${id}`);
    if (response.status) {
      setEditId(id);
      setEditName(response.data.name);
      setEditedDesigation(response.data.designation);
    }
  };
  const handelUpdate = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      console.log(image);
      formDataToSend.append("name", editName);
      formDataToSend.append("designation", editdesigation);

      formDataToSend.append("image", editimage);
      const response = await fetch(`${ApiUrl.BIVABDEV_TEAM_UPDATE}${editId}`, {
        method: "PUT",
        body: formDataToSend,
      });

      if (!response.status) {
        throw new Error("Failed to add team");
      }
      setName("");
      setDesignation("");

      fetchData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleFileChange = (event) => {
    setImage(event.target.files[0]);
    console.log('Selected file:', event.target.files[0]);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      console.log(image);
      formDataToSend.append("name", name);
      formDataToSend.append("designation", designation);

      formDataToSend.append("image", image);
      const response = await fetch(ApiUrl.BIVABDEV_TEAM_CREATE, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.status) {
        throw new Error("Failed to add team");
      }
      setName("");
      setDesignation("");

      fetchData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${ApiUrl.ImageHostURl}${row.image}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Action",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'green'}}/>
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header title="Team" subtitle="List of team details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAddModalOpen} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW TEAM
            </Button>
          </Box>
        </Box>
        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">
                Add New
              </Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    id="name"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="designation"
                    label="Designation"
                    fullWidth
                    value={designation}
                    onChange={(e) => setDesignation(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="image"
                    label="Image"
                    fullWidth
                    type="file"
                    onChange={handleFileChange}
                    // InputLabelProps={{ shrink: true }}
                    // onChange={(e) => setImage(e.target.files[0])}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" color="error">
                  CANCEL
                </Button>
                <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handelUpdate}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  id="name"
                  label="Name"
                  value={editName}
                  onChange={(e) => setEditName(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="designation"
                  label="Designation"
                  value={editdesigation}
                  onChange={(e) => setEditedDesigation(e.target.value)}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="image"
                  label="Image"
                  fullWidth
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEditImage(e.target.files[0])}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default Team;
