import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Modal,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import { mockDataYashilaGallery } from "../../data/mockData";
import DataTable from "react-data-table-component";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { useNavigate } from "react-router-dom";
import { Edit, Delete, Close } from "@mui/icons-material";
import { DELETENETWORK, GETNETWORK, PUTNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";
const YashilaGallery = () => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(mockDataYashilaGallery);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    image: "",
  });
  const [image, setImage] = useState("");
  useEffect(() => {
    fetchData();
  }, []);
  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.GALLERY_GETALL);
    console.log("1stresponse", response);
    setData(response.data);
  };
  const handleAdd = () => {
    navigate("/yashilagallery/addyashilagallery");
  };

  const editForm = (id) => {
    setSelectedItemId(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: "",
      image: "",
    });
  };

  const deleteData = async (id) => {
    await deleteDataByid(id);
  };

  const deleteDataByid = async (id) => {
    await DELETENETWORK(`${ApiUrl.GALLERY_DELETE}/${id}`);
    fetchData();
    console.log("Item with ID:", id, "has been deleted.");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();

      formDataToSend.append("image", image);
      const response = await fetch(ApiUrl.GALLERY_POST, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.status) {
        throw new Error("Failed to add team");
      }
      fetchData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {};

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };
  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${ApiUrl.ImageHostURl}${row.imageName}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },

    {
      name: "Access Level",
      cell: (row) => (
        <div>
          {/* <Button onClick={() => editForm(row.id)} style={customStyles.editButton}>
            <Edit />
          </Button> */}
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </div>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Yashila Photo Gallery"
          subtitle="You can add or modify gallery details"
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAdd} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
            <Box sx={{ display: "flex", alignItems: "center" }}></Box>
          </div>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>

          {/* <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', height: '100%', width: '350px', boxShadow: '0px 0px 10px 2px black', borderRadius: '5px' }}>
            <Box style={{ flex: '1 0 50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px' }}>
              <img src="/assets/gallery.jpeg" alt="Your Image" style={{ maxWidth: '100%', height: '240px' }} />
            </Box>
            <br />
            <Typography variant='h5' style={{ marginLeft: '2px', color: 'black' }}>This is gallery page.</Typography>
            <br />

          </Box> */}
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={(e) => handleSubmit(e, formData)}>
            <Grid item xs={12}>
            <TextField
                    id="image"
                    label="Image"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setImage(e.target.files[0])}
              />
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default YashilaGallery;
