import React, { useState, useEffect } from "react";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  styled,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { mockDataBivabBlog } from "../../data/mockData";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
import { GETNETWORK, DELETENETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";
import Login from "../public/Login";

const Career = () => {
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    fullName: "",
    contactNo: "",
    emailId: "",
    location: "",
    gender: "",
    education: "",
    positionApplyingFor: "",
    totalExperience: "",
    imageName: "",
  });

  // const [fullName, setFullName] = useState("");
  // const [contactNo, setContactNo] = useState("");
  // const [emailId, setEmailId] = useState("");
  // const [location, setLocation] = useState("");
  // const [gender, setGender] = useState("");
  // const [education, setEducation] = useState("");
  // const [positionApplyingFor, setPositionApplyingFor] = useState("");
  // const [totalExperience, setTotalExperience] = useState("");
  // const [image, setImage] = useState("");

  const [editId, setEditId] = useState("");
  const [editFullName, setEditFullName] = useState("");
  const [editContactNo, setEditContactNo] = useState("");
  const [editEmailId, setEditEmailId] = useState("");
  const [editLocation, setEditLocation] = useState("");
  const [editGender, setEditGender] = useState("");
  const [editEducation, setEditEducation] = useState("");
  const [editPositionApplyingFor, setEditPositionApplyingFor] = useState("");
  const [editTotalExperience, setEditTotalExperience] = useState("");
  const [editImage, setEditImage] = useState(null);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_CAREER_GETALL);
    console.log(response);
    setData(response.data);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const deleteData = async (id) => {
    const responses = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_CAREER_DELETE}${id}`
    );
    if (responses.status) {
      fetchData();
    }
  };

  // const handelUpdate = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const formDataToSend = new FormData();
  //     formDataToSend.append("name", editName);
  //     formDataToSend.append("designation", editdesigation);

  //     formDataToSend.append("image", editImage);
  //     const response = await fetch(`${ApiUrl.BIVABDEV_CAREER_UPDATE}${editId}`, {
  //       method: "PUT",
  //       body: formDataToSend,
  //     });

  //     if (!response.status) {
  //       throw new Error("Failed to add team");
  //     }
  //     setName("");
  //     setDesignation("");

  //     fetchData();
  //     closeModal();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const formDataToSend = new FormData();
  //     console.log(image);
  //     formDataToSend.append("name", name);
  //     formDataToSend.append("designation", designation);

  //     formDataToSend.append("image", image);
  //     const response = await fetch(ApiUrl.BIVABDEV_CAREER_CREATE, {
  //       method: "POST",
  //       body: formDataToSend,
  //     });

  //     if (!response.status) {
  //       throw new Error("Failed to add team");
  //     }
  //     setName("");
  //     setDesignation("");

  //     fetchData();
  //     closeModal();
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getItemByid = async (id) => {
    const response = await GETNETWORK(`${ApiUrl.BIVABDEV_CAREER_GETBYID}${id}`);
    if (response.status) {
      setEditId(id);
      setEditFullName(response.data.fullName);
      setEditContactNo(response.data.contactNo);
      setEditEmailId(response.data.emailId);
      setEditLocation(response.data.location);
      setEditGender(response.data.gender);
      setEditEducation(response.data.education);
      setEditPositionApplyingFor(response.data.positionApplyingFor);
      setEditTotalExperience(response.data.totalExperience);
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    getItemByid(id);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setFormData({
      id: "",
      fullName: "",
      contactNo: "",
      emailId: "",
      location: "",
      gender: "",
      education: "",
      positionApplyingFor: "",
      totalExperience: "",
      imageName: "",
    });
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.contactNo,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.emailId,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Education",
      selector: (row) => row.education,
      sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.positionApplyingFor,
      sortable: true,
    },
    {
      name: "Experience",
      selector: (row) => row.totalExperience,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <Button
          variant="contained"
          href={`${ApiUrl.ImageHostURl}${row.imageName}`}
          download
        >
          Resume
        </Button>
      ),
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <VisibilityRoundedIcon style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  if(!localStorage.getItem('email') && !localStorage.getItem('password'))  return <Login/>

  return (
    <Layout>
      <Box m="20px">
        <Header title="Career" subtitle="List of career details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button variant="contained" onClick={handleAddModalOpen}>ADD NEW DETAILS</Button>
          </Box> */}
        </Box>
        {/* <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: 600,
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
              <Typography variant="h3" component="h3">
                Add New Details
              </Typography>
              <IconButton onClick={closeModal} aria-label="Close" sx={{ position: 'absolute', top: 0, right: 0 }}>
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField id="fullName" label="Name" fullWidth  value={fullName} onChange={(e) => setFullName(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="contactNo" label="Phone" fullWidth value={contactNo} onChange={(e) => setContactNo(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="emailId" label="Email" fullWidth value={emailId} onChange={(e) => setEmailId(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="location" label="Location" fullWidth value={location} onChange={(e) => setLocation(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="gender" label="Gender" fullWidth value={gender} onChange={(e) => setGender(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="education" label="Education" fullWidth value={education} onChange={(e) => setEducation(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="positionApplyingFor" label="Position" fullWidth value={positionApplyingFor} onChange={(e) => setPositionApplyingFor(e.target.value)}/>
                </Grid>
                <Grid item xs={6}>
                  <TextField id="totalExperience" label="Experience" fullWidth value={totalExperience} onChange={(e) => setTotalExperience(e.target.value)}/>
                </Grid>
                <Grid item xs={12}>
                  <TextField id="imageName" label="Image" fullWidth type="file" InputLabelProps={{ shrink: true }} onChange={(e) => setImageName(e.target.files[0])}/>
                </Grid>
              </Grid>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2 }}>
                <Button variant="contained" onClick={closeModal} color="error">
                  CANCEL
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal> */}
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              View Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="fullName"
                  label="Name"
                  fullWidth
                  value={editFullName}
                  onChange={(e) => setEditFullName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="contactNo"
                  label="Phone"
                  fullWidth
                  value={editContactNo}
                  onChange={(e) => setEditContactNo(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="emailId"
                  label="Email"
                  fullWidth
                  value={editEmailId}
                  onChange={(e) => setEditEmailId(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="location"
                  label="Location"
                  fullWidth
                  value={editLocation}
                  onChange={(e) => setEditLocation(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="gender"
                  label="Gender"
                  fullWidth
                  value={editGender}
                  onChange={(e) => setEditGender(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="education"
                  label="Education"
                  fullWidth
                  value={editEducation}
                  onChange={(e) => setEditEducation(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="positionApplyingFor"
                  label="Position"
                  fullWidth
                  value={editPositionApplyingFor}
                  onChange={(e) => setEditPositionApplyingFor(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="totalExperience"
                  label="Experience"
                  fullWidth
                  value={editTotalExperience}
                  onChange={(e) => setEditTotalExperience(e.target.value)}
                />
              </Grid>
              {/* <Grid item xs={12}>
                <TextField id="imageName" label="Image" fullWidth type="file" InputLabelProps={{ shrink: true }} onChange={(e) => setEditImageName(e.target.files[0])}/>
              </Grid> */}
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              {/* <Button type="submit" variant="contained" color="primary">
                Submit
              </Button> */}
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default Career;
