import React, { useEffect, useState } from "react";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Typography,
} from "@mui/material";
import Layout from "../../../components/global/Layout";
import { POSTNETWORK } from "../../../utils/network";
import ApiUrl from "../../../utils/url";
import { useNavigate } from "react-router-dom";

export default function AddYashilaVideo() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [domLoaded, setDomLoaded] = useState(false);
  const [formData, setFormData] = useState({
    link: "",
    name: "",
    title: "",
    isActive: true,
  });

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await POSTNETWORK(ApiUrl.VIDEO_POST, formData);
    if (response.status) {
      navigate("/yashilavideo");
    }
  };

  return (
    <Layout>
      {domLoaded && (
        <Box
          m="20px"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Box width="50vw">
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Add Yashila Video
            </Typography>
            <br />

            <Card variant="outlined">
              <CardContent sx={{ border: "1px solid gray" }}>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", marginBottom: "20px" }}
                >
                  Add Details
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="link"
                        label="Link"
                        variant="outlined"
                        fullWidth
                        value={formData.link}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="title"
                        label="Title"
                        variant="outlined"
                        fullWidth
                        value={formData.title}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} my={4}>
                      <Button
                        type="submit"
                        variant="contained"
                        color="error"
                        fullWidth
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
