import React, { useState } from "react";
import {
  Box,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  Card,
  CardContent,
  CardActions,
  Button,
} from "@mui/material";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { useNavigate } from "react-router-dom";
import { Edit, Delete, Close } from "@mui/icons-material";

const YashilaMision = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Box m="20px">
        <Header title="About Yashila Pages." />
        <br />
        <br />
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  About Mision
                </Typography>
                <Typography color="textSecondary">
                  Add details about mision
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  onClick={() => navigate("/yashilaabout-mision")}
                >
                  Click Here
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  About Vision
                </Typography>
                <Typography color="textSecondary">
                  Add details about vision
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  onClick={() => navigate("/yashila-vision")}
                >
                  Click Here
                </Button>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography
                  variant="h5"
                  sx={{ fontWeight: "bold" }}
                >
                  About Count Up
                </Typography>
                <Typography color="textSecondary">
                  Add details about count up
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                  onClick={() => navigate("/yashila-count-up")}
                >
                  Click Here
                </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default YashilaMision;
