import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Layout from "../../../components/global/Layout";
import { tokens } from "../../../constants/theme";
import { POSTNETWORK } from "../../../utils/network";
import ApiUrl from "../../../utils/url";
import { useNavigate } from "react-router-dom";

export default function AddYashilaGetintouchinfo() {
  const navigate = useNavigate();

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  const [location, setLocation] = useState("");
  const [email, setEmail] = useState("");
  const [primary_number, setPrimaryNumber] = useState("");
  const [secondary_number, setSecondaryNumber] = useState("");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      location: location,
      email: email,
      primaryNumber: primary_number,
      secondaryNumber: secondary_number,
    };
    try {
      const formDataResponse = await POSTNETWORK(
        ApiUrl.GETINTOUCH_POST,
        formData
      );
      if (formDataResponse.status) {
        navigate("/yashilagetintouchinfo");
      }
      console.log(formData);
    } catch (error) {
      console.error("Error while submitting form:", error);
    }
  };

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px" width="70vw">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Yashila Get In Touch(INFO)
          </Typography>
          <br />
          <Box sx={{ display: "flex" }}>
            <Box width="70vw">
              <Card variant="outlined" sx={{ marginLeft: "60px" }}>
                <CardContent sx={{ border: "1px solid gray" }}>
                  <h5 class="card-title text-center">Add Details</h5>
                  <br />
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="location"
                          label="Location"
                          variant="outlined"
                          fullWidth
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="primary_number"
                          label="Primary Number"
                          variant="outlined"
                          fullWidth
                          value={primary_number}
                          onChange={(e) => setPrimaryNumber(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="secondary_number"
                          label="Secondary Number"
                          variant="outlined"
                          fullWidth
                          value={secondary_number}
                          onChange={(e) => setSecondaryNumber(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={handleFormSubmit}
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
