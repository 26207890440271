// 3D Model index.jsx

import { useEffect } from "react";

import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import SceneInit from "../../lib/SceneInit";
import { Box } from "@mui/material";

function Building() {
  useEffect(() => {
    const test = new SceneInit("myThreeJsCanvas");
    test.initialize();
    test.animate();

    const gltfLoader = new GLTFLoader();
    gltfLoader.load(`/assets/building/scene.gltf`, (gltfScene) => {
      test.scene.add(gltfScene.scene);
    });
  }, []);

  return (
    <Box>
      <canvas id="myThreeJsCanvas" />
    </Box>
  );
}

export default Building;
