import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  styled,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { mockDataBivabBlog } from "../../data/mockData";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
import {
  GETNETWORK,
  DELETENETWORK,
  POSTNETWORK,
  PUTNETWORK,
} from "../../utils/network";
import ApiUrl from "../../utils/url";
const Knowmore = () => {
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    title: "",
    description: "",
    image: "",
  });

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const [editId, setEditId] = useState("");
  const [editTitle, setEditTitle] = useState("");
  const [editDescription, setEditedDescription] = useState("");
  const [editImage, setEditImage] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_KNOWMORE_GETALL);
    console.log(response);
    setData(response.data);
  };

  const handelUpdate = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      console.log(image);
      formDataToSend.append("title", editTitle);
      formDataToSend.append("description", editDescription);
      formDataToSend.append("image", editImage);

      const response = await fetch(
        `${ApiUrl.BIVABDEV_KNOWMORE_UPDATE}${editId}`,
        {
          method: "PUT",
          body: formDataToSend,
        }
      );

      if (!response.status) {
        throw new Error("Failed to add team");
      }
      setTitle("");
      setDescription("");

      fetchData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    getItemByid(id);
    setIsEditModalOpen(true);
  };

  const getItemByid = async (id) => {
    const response = await GETNETWORK(
      `${ApiUrl.BIVABDEV_KNOWMORE_GETBYID}${id}`
    );
    if (response.status) {
      setEditId(response.data.id);
      setEditTitle(response.data.title);
      setEditedDescription(response.data.description);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", title);
      formDataToSend.append("description", description);
      formDataToSend.append("image", image);

      const response = await fetch(ApiUrl.BIVABDEV_KNOWMORE_CREATE, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.status) {
        throw new Error("Failed to add team");
      }
      setTitle("");
      setDescription("");

      fetchData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const deleteData = async (id) => {
    const responses = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_KNOWMORE_DELETE}${id}`
    );
    if (responses.status) {
      fetchData();
    }
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setFormData({
      id: "",
      title: "",
      description: "",
      image: "",
    });
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${ApiUrl.ImageHostURl}${row.imageName}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header title="Know More" subtitle="List of know more details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAddModalOpen} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
          </Box>
        </Box>
        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">
                Add New Details
              </Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="title"
                    label="Title"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="description"
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="imageName"
                    label="Image"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" onClick={closeModal} color="error">
                  CANCEL
                </Button>
                <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handelUpdate}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="title"
                  label="Title"
                  fullWidth
                  value={editTitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="description"
                  label="Description"
                  fullWidth
                  value={editDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="imageName"
                  label="Image"
                  fullWidth
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEditImage(e.target.files[0])}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default Knowmore;
