import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import EnquiryMobile from "../../../components/reusable/mobile/EnquiryMobile";

export default function AddEnquiry() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="60px" width="70vw">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            ENQUIRY
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box width="60vw">
              <Card variant="outlined" style={{ marginTop: "40px" }}>
                <CardContent style={{ border: "1px solid gray" }}>
                  <Typography
                    variant="h6"
                    sx={{ fontWeight: "bold", textAlign: "center" }}
                  >
                    Add Details
                  </Typography>
                  <br />
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="ID"
                          label="ID"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="question"
                          label="Question"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="option1"
                          label="Option 1"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="option2"
                          label="Option 2"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="option3"
                          label="Option 3"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="option4"
                          label="Option 4"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button variant="contained" color="error" fullWidth>
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "39vw" }}
            >
              <Box
                sx={{
                  height: "90vh",
                  border: "1px solid",
                  padding: "0px 10px",
                  paddingTop: "57px",
                  borderRadius: "30px",
                  backgroundColor: colors.black[200],
                }}
              >
                <EnquiryMobile />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
