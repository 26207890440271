import { useRef } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../constants/theme";
import { mockDataTestimonial } from '../../../data/mockData';

import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import Carousel from 'react-material-ui-carousel';

const TestimonialMobile = () => {
    const contentRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


  return (
    <Box sx={{height:'76.5vh', width:'22.22vw', border:`3px solid ${colors.black[100]}`, borderRadius:'8px', backgroundColor:colors.white[100]}}>
        <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
            <Box ref={contentRef} sx={{height: '800px', position: 'relative' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="2px 11px" marginBottom="3px" sx={{backgroundColor: colors.blue[100] }}>
                        <Typography
                                variant="h4"
                                color={colors.white[100]}
                                fontWeight="bold"
                                sx={{ m: "10px 0 0 0", marginTop:'-5px'}}
                            >
                            Testimonials
                        </Typography>
                        <img
                                alt="profile-user"
                                width="80px"
                                height="50px"
                                src={`/assets/profile-logo.png`}
                                style={{ cursor: "pointer" , filter:'grayscale(1) contrast(0) brightness(1000)'}}
                            />
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                            <img
                                alt="profile-user"
                                width="326px"
                                height="170px"
                                src={"/assets/testbg.jpg"}
                                style={{ cursor: "pointer"}}
                            />
                    </Box>


                    {/* Testimonial Carousel start */}

                    <Carousel>

                        {mockDataTestimonial.map((item) => (
                            <Box key={item.id} sx={{display:'flex', alignItems:'center', justifyContent:'center'}}>
                                    <Box sx={{
                                            display:'flex',
                                            flexDirection:'column', 
                                            textAlign:'center',
                                            alignItems:'center',
                                            justifyContent:'center',
                                            width:'234px',
                                            height:'251px',
                                            marginTop:'29px',
                                            backgroundColor: colors.white[100],
                                            borderRadius:'10px',
                                            boxShadow:'4px 4px 7px #888888, -4px -4px 8px #888888'
                                            }}>
                                        <Typography
                                        variant="h5"
                                        color={colors.greenAccent[500]}
                                        fontWeight="bold"
                                        >
                                        {item.name}
                                        </Typography>
                                        <Typography
                                        variant="h6"
                                        color={colors.grey[100]}
                                        fontWeight="400"
                                        sx={{ m: "10px 0 0 0", padding:'0px 10px'}}
                                        >
                                        {item.description}
                                        </Typography>
                                        <Typography
                                        variant="h5"
                                        color={colors.grey[100]}
                                        fontWeight="bold"
                                        sx={{ m: "10px 0 0 0", padding:'0px 10px'}}
                                        >
                                        {item.email}
                                        </Typography>
                                    </Box>
                            </Box>
                        ))}
                    </Carousel>

                    {/* Testimonial Carousel end */}


                <Box marginTop="25px" display="flex" flexDirection="column" justifyContent="center">
                    <img
                        alt="profile-user"
                        width="326px"
                        height="170px"
                        src={"/assets/aboutbg.jpg"}
                        style={{ cursor: "pointer"}}
                    />
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" marginTop="25px" height="70px" sx={{backgroundColor: colors.black[100]}}>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{ color: colors.white[100], marginRight:'15px'}}
                        >
                          Also contact us in
                    </Typography>
                    <WhatsAppIcon sx={{color:'white', backgroundColor:'#21d921', width:'36px', height: '38px', borderRadius:'26px'}}/>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default TestimonialMobile