import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/global/Layout";
import { tokens } from "../../../constants/theme";
import { POSTNETWORK } from "../../../utils/network";
import ApiUrl from "../../../utils/url";

export default function AddYashilaAboutVision() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
    isActive: false,
    type: "V",
  });

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await POSTNETWORK(ApiUrl.CreateMissionVision, formData);
    if (response.status) {
      navigate("/yashilaabout-mision");
    }
  };

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px" width="50vw">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Yashila Mision
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex" }}>
            <Box width="100%">
              <Card variant="outlined" sx={{ marginLeft: "250px" }}>
                <CardContent sx={{ border: "1px solid gray" }}>
                  <h5 className="card-title text-center">Add Details</h5>
                  <br />
                  <br />
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="title"
                          label="title"
                          variant="outlined"
                          fullWidth
                          value={formData.title}
                          onChange={handleChange}
                        />
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          id="description"
                          label="description"
                          variant="outlined"
                          fullWidth
                          value={formData.description}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="error"
                          fullWidth
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
