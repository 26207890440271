import { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import HomeMobile from "../../../components/reusable/mobile/HomeMobile";

export default function AddHome() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Home
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <br />
              <br />
              <Box
                sx={{ p: 2 }}
                style={{
                  border: "1px solid gray",
                  marginLeft: "40px",
                  width: "40vw",
                }}
              >
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Add Details
                </Typography>
                <br />
                <br />
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField id="ID" label="ID" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="inputGroupFile01"
                        label="Image"
                        fullWidth
                        type="file"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField id="title" label="Title" fullWidth />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        label="Description"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} align="center">
                      <Button
                        variant="contained"
                        color="secondary"
                        type="submit"
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2, display: "flex", justifyContent: "center" }}>
                <Box
                  sx={{
                    height: "90vh",
                    border: "1px solid",
                    padding: "0px 10px",
                    paddingTop: "57px",
                    borderRadius: "30px",
                    backgroundColor: colors.black[200],
                  }}
                >
                  <HomeMobile />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Layout>
  );
}
