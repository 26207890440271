import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Layout from "../../components/global/Layout";
import Header from "../../components/reusable/Header";
import {
  DELETENETWORK,
  GETNETWORK,
  POSTNETWORK,
  PUTNETWORK,
} from "../../utils/network";
import ApiUrl from "../../utils/url";
const BivabDevelopersVision = () => {
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [data, setData] = useState([]);

  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [editId, setEditId] = useState("");
  const [edittitle, setEditTitle] = useState("");
  const [editdescription, setEditDescription] = useState("");
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_VISSION_GETALL);
    setData(response.data);
  };
  const getByID = async (id) => {
    const response = await GETNETWORK(
      `${ApiUrl.BIVABDEV_MISSION_VISSION_GETBYID}${id}`
    );
    if (response.status) {
      setEditId(id);
      setEditTitle(response.data.title);
      setEditDescription(response.data.description);
    }
  };
  const editForm = (id) => {
    setSelectedItemId(id);
    getByID(id);
    setIsEditModalOpen(true);
  };
  const deleteData = async (id) => {
    const response = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_MISSION_VISSION_DELETE}${id}`
    );
    if (response.status) {
      fetchData();
    }
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const payload = {
      title: edittitle,
      description: editdescription,
      isMission: false,
      isActive: true,
    };

    const response = await PUTNETWORK(
      `${ApiUrl.BIVABDEV_MISSION_VISSION_UPDATE}${editId}`,
      payload
    );
    if (response.status) {
      setEditId("");
      setEditTitle("");
      setEditDescription("");
      fetchData();
      closeModal();
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const payload = {
      title: title,
      description: description,
      isMission: false,
      isActive: true,
    };
    const response = await POSTNETWORK(
      `${ApiUrl.BIVABDEV_MISSION_VISSION_ADD}`,
      payload
    );
    if (response.status) {
      setTitle("");
      setDescription("");
      fetchData();
      closeModal();
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Grid container spacing={1}>
          <Grid item>
            <IconButton
              onClick={() => editForm(row.id)}
              style={customStyles.editButton}
            >
              <Edit style={{color:'green'}}/>
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => deleteData(row.id)}
              style={customStyles.deleteButton}
            >
              <Delete style={{color:'red'}}/>
            </IconButton>
          </Grid>
        </Grid>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header title="Vision" subtitle="List of vision details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAddModalOpen} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
          </Box>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
                search: true,
                searchPlaceholder: "Search...",
              }}
            />
          </Box>
        </Box>
      </Box>

      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              {isEditModalOpen ? "Edit Form" : "Add New Details"}
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleUpdate}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  variant="outlined"
                  value={edittitle}
                  onChange={(e) => setEditTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  label="Description"
                  variant="outlined"
                  value={editdescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                {isEditModalOpen ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <Modal open={isAddModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              {isEditModalOpen ? "Edit Form" : "Add New Details"}
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="description"
                  label="Description"
                  variant="outlined"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                {isEditModalOpen ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default BivabDevelopersVision;
