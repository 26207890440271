import { ColorModeContext, useMode } from '../../constants/theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import Topbar from './Topbar';
import Sidebar from './Sidebar';
import { useEffect, useState } from 'react';


export default function Layout({ children }) {

  const [theme, colorMode] = useMode();
  const [domLoaded, setDomLoaded] = useState(false);
    useEffect(() => {
        setDomLoaded(true);
    }, []);


  return (
    <>
    {domLoaded && (
      <ColorModeContext.Provider value= {colorMode} style={{ overflow: 'hidden' }}>
      <ThemeProvider theme={theme}>
        <CssBaseline/>
        <div className='app'>
          <Sidebar/>
          <main className='content'>
            <Topbar/>
            {children}
          </main>
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider> 
    )}
    </> 
  )
}
