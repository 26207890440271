import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/global/Layout";
import { POSTNETWORK } from "../../../utils/network";
import ApiUrl from "../../../utils/url";

export default function AddYashilaVideo() {
  const navigate = useNavigate();
  const theme = useTheme();
  const [domLoaded, setDomLoaded] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    image: "",
    description: "",
    about: "",
  });

  useEffect(() => {
    setDomLoaded(true);
  }, []);
  const handleChange = (e) => {
    const { id, value } = e.target;
    if (id === "inputGroupFile01") {
      setFormData({ ...formData, image: e.target.files[0] });
    } else {
      setFormData({ ...formData, [id]: value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const response = await POSTNETWORK(ApiUrl.OWNER_POST, formData);
    if (response.status) {
      navigate("/yashilaabout-bivab-owner");
    }
  };

  return (
    <Layout>
      {domLoaded && (
        <Box
          m="20px"
          width="100%"
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <Box width="50vw">
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", textAlign: "center" }}
            >
              Add Yashila Owner
            </Typography>
            <br />

            <Card variant="outlined">
              <CardContent sx={{ border: "1px solid gray" }}>
                <Typography
                  variant="h5"
                  sx={{ textAlign: "center", marginBottom: "20px" }}
                >
                  Add Details
                </Typography>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Image"
                        id="inputGroupFile01"
                        type="file"
                        fullWidth
                        value={formData.image}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="description"
                        label="Description"
                        variant="outlined"
                        fullWidth
                        value={formData.description}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="about"
                        label="About"
                        variant="outlined"
                        fullWidth
                        value={formData.about}
                        onChange={handleChange}
                      />
                    </Grid>
                    <Grid item xs={12} my={4}>
                      <Button
                        variant="contained"
                        color="error"
                        fullWidth
                        type="submit"
                      >
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
