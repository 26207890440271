import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  Switch,
  FormControlLabel,
  styled,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { mockDataUser } from "../../data/mockData";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";

const User = () => {
  const [filteredData, setFilteredData] = useState(mockDataUser);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    profile: "",
    name: "",
    email: "",
    phone: "",
    address: "",
  });

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setFormData({
      id: "",
      profile: "",
      name: "",
      email: "",
      phone: "",
      address: "",
    });
  };

  const deleteData = (id) => {
    const updatedData = filteredData.filter((item) => item.id !== id);
    setFilteredData(updatedData);
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    closeModal();
  };

  const Android12Switch = styled(Switch)(({ theme, checked }) => ({
    padding: 8,
    "& .MuiSwitch-track": {
      borderRadius: 22 / 2,
      backgroundColor: checked ? "#4caf50" : "#ccc", // Change background color when active
      "&::before, &::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        transform: "translateY(-50%)",
        width: 16,
        height: 16,
      },
      "&::before": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
        left: 12,
      },
      "&::after": {
        backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
          theme.palette.getContrastText(theme.palette.primary.main)
        )}" d="M19,13H5V11H19V13Z" /></svg>')`,
        right: 12,
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "none",
      width: 16,
      height: 16,
      margin: 2,
    },
  }));

  // eslint-disable-next-line no-unused-vars
  const handleSwitchChange = (id) => {
    setFilteredData((prevData) => {
      return prevData.map((item) => {
        if (item.id === id) {
          console.log(
            `Switch for ID ${id} is now ${!item.active ? "active" : "inactive"}`
          );
          return { ...item, active: !item.active }; // Toggle active state
        }
        return item;
      });
    });
  };

  const columns = [
    {
      name: "Profile",
      cell: (row) => (
        <img
          src={`/assets/${row.profile}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <Edit />
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete />
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header title="USERS" subtitle="List of user details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              onClick={handleAddModalOpen}
              sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              ADD A USER
            </Button>
          </Box>
        </Box>
        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">Add A New User</Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField id="name" label="Name" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="email" label="Email" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="phone" label="Phone" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField id="address" label="Address" fullWidth />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="inputGroupFile01"
                    label="Profile"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" onClick={closeModal} color="error">
                  Cancel
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  SAVE
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="h2">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={(e) => handleSubmit(e, formData)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField id="ID" label="ID" fullWidth />
              </Grid>

              <Grid item xs={12}>
                <TextField id="name" label="Name" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField id="email" label="Email" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField id="phone" label="Phone" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField id="address" label="Address" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="inputGroupFile01"
                  label="Profile"
                  fullWidth
                  type="file"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default User;
