import React from "react";
import { BrowserRouter, Route, Routes, useNavigate } from "react-router-dom";
import Topbar from "../components/global/Topbar";
import Sidebar from "../components/global/Sidebar";

// Import Components
import About from "../screen/private/About";
import Answers from "../screen/private/Answers";
import BivabDeveloperBlog from "../screen/private/BivabDeveloperBlog";
import BivabDeveloperCareer from "../screen/private/BivabDeveloperCareer";
import BivabDeveloperCarousel from "../screen/private/BivabDeveloperCarousel";
import BivabDeveloperContacts from "../screen/private/BivabDeveloperContacts";
import BivabDeveloperEnquiryNow from "../screen/private/BivabDeveloperEnquiryNow";
import BivabDeveloperGetintouch from "../screen/private/BivabDeveloperGetintouch";
import BivabDeveloperHeroHomeCarousel from "../screen/private/BivabDeveloperHeroHomeCarousel";
import BivabDeveloperHome from "../screen/private/BivabDeveloperHome";
import BivabDeveloperHomeAboutbivab from "../screen/private/BivabDeveloperHomeAboutbivab";
import BivabDeveloperKnowmore from "../screen/private/BivabDeveloperKnowmore";
import BivabDeveloperMision from "../screen/private/BivabDeveloperMision";
import BivabDeveloperOwner from "../screen/private/BivabDeveloperOwner";
import BivabDeveloperResolveEnquiry from "../screen/private/BivabDeveloperResolveEnquiry";
import BivabDeveloperTeam from "../screen/private/BivabDeveloperTeam";
import BivabDeveloperTestimonial from "../screen/private/BivabDeveloperTestimonial";
import BivabDeveloperVision from "../screen/private/BivabDeveloperVision";
import BivabManageUser from "../screen/private/BivabManageUser";
import Dashboard from "../screen/private/Dashboard";
import Employee from "../screen/private/Employee";
import Enquiry from "../screen/private/Enquiry";
import Projects from "../screen/private/Projects";
import ThreeDModel from "../screen/private/ThreeDModel";
import User from "../screen/private/User";
import Login from "../screen/public/Login";

// Yashila Import Components
import YashilaAbout from "../screen/private/YashilaAbout";
import YashilaAboutBivab from "../screen/private/YashilaAboutBivab";
import YashilaAboutCommercial from "../screen/private/YashilaAboutCommercial";
import YashilaAboutOwners from "../screen/private/YashilaAboutOwners";
import YashilaContact from "../screen/private/YashilaContact";
import YashilaCountUp from "../screen/private/YashilaCountUp";
import YashilaGallery from "../screen/private/YashilaGallery";
import YashilaGetintouch from "../screen/private/YashilaGetintouch";
import YashilaGetintouchinfo from "../screen/private/YashilaGetintouchinfo";
import YashilaMision from "../screen/private/YashilaMision";
import YashilaProperties from "../screen/private/YashilaProperties";
import YashilaTour from "../screen/private/YashilaTour";
import YashilaVideo from "../screen/private/YashilaVideo";
import YashilaVision from "../screen/private/yashlacrms/YashilaVision";

// Form
import AddAbout from "../screen/private/form/AddAbout";
import AddContact from "../screen/private/form/AddContact";
import AddEmployee from "../screen/private/form/AddEmployee";
import AddEnquiry from "../screen/private/form/AddEnquiry";
import AddHome from "../screen/private/form/AddHome";
import AddProject from "../screen/private/form/AddProject";
import AddTestimonial from "../screen/private/form/AddTestimonial";

//Yashila Add Form
import AddYashilaAboutBivab from "../screen/private/form/AddYashilaAboutBivab";
import AddYashilaAboutCommercial from "../screen/private/form/AddYashilaAboutCommercial";
import AddYashilaAboutMision from "../screen/private/form/AddYashilaAboutMision";
import AddYashilaAboutOwners from "../screen/private/form/AddYashilaAboutOwners";
import AddYashilaAboutVision from "../screen/private/form/AddYashilaAboutVision";
import AddYashilaContact from "../screen/private/form/AddYashilaContact";
import AddYashilaCountUp from "../screen/private/form/AddYashilaCountUp";
import AddYashilaGallery from "../screen/private/form/AddYashilaGallery";
import AddYashilaGetintouch from "../screen/private/form/AddYashilaGetintouch";
import AddYashilaGetintouchinfo from "../screen/private/form/AddYashilaGetintouchinfo";
import AddYashilaProperties from "../screen/private/form/AddYashilaProperties";
import AddYashilaVideo from "../screen/private/form/AddYashilaVideo";
import YashilaAboutMision from "../screen/private/yashlacrms/YashilaAboutMision";

function Routing() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/home" element={<BivabDeveloperHome />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/enquiry" element={<Enquiry />} />
        <Route path="/employee" element={<Employee />} />
        <Route path="/user" element={<User />} />
        <Route path="/answers" element={<Answers />} />
        <Route path="/testimonial" element={<BivabDeveloperTestimonial />} />
        <Route path="/contacts" element={<BivabDeveloperContacts />} />
        <Route path="/career" element={<BivabDeveloperCareer />} />
        <Route path="/threedmodel" element={<ThreeDModel />} />
        <Route path="/getintouch" element={<BivabDeveloperGetintouch />} />
        <Route path="/owner" element={<BivabDeveloperOwner />} />
        <Route path="/team" element={<BivabDeveloperTeam />} />
        <Route path="/carousel" element={<BivabDeveloperCarousel />} />
        <Route path="/knowmore" element={<BivabDeveloperKnowmore />} />
        <Route path="/blog" element={<BivabDeveloperBlog />} />
        <Route path="/mision" element={<BivabDeveloperMision />} />
        <Route path="/vision" element={<BivabDeveloperVision />} />
        <Route path="/enquirynow" element={<BivabDeveloperEnquiryNow />} />
        <Route
          path="/homeaboutbivab"
          element={<BivabDeveloperHomeAboutbivab />}
        />
        <Route
          path="/herohomecarousel"
          element={<BivabDeveloperHeroHomeCarousel />}
        />
        <Route
          path="/resolveenquiry"
          element={<BivabDeveloperResolveEnquiry />}
        />

        {/* Bivab User Routes*/}
        <Route path="/bivabmanageuser" element={<BivabManageUser />} />

        {/* Yashila Routes */}
        <Route path="/yashilagetintouch" element={<YashilaGetintouch />} />
        <Route
          path="/yashilagetintouchinfo"
          element={<YashilaGetintouchinfo />}
        />
        <Route path="/yashilagallery" element={<YashilaGallery />} />
        <Route path="/yashilacontact" element={<YashilaContact />} />
        <Route path="/yashilatour" element={<YashilaTour />} />
        <Route path="/yashilaproperties" element={<YashilaProperties />} />
        <Route path="/yashilaabout" element={<YashilaAbout />} />
        <Route path="/yashilavideo" element={<YashilaVideo />} />
        <Route path="/yashilamision" element={<YashilaMision />} />
        <Route path="/yashilaabout-bivab" element={<YashilaAboutBivab />} />
        <Route
          path="/yashilaabout-bivab-owner"
          element={<YashilaAboutOwners />}
        />
        <Route
          path="/yashilaabout-bivab-commercial"
          element={<YashilaAboutCommercial />}
        />
        <Route path="/yashilaabout-mision" element={<YashilaAboutMision />} />
        <Route path="/yashila-vision" element={<YashilaVision />} />
        <Route path="/yashila-count-up" element={<YashilaCountUp />} />

        <Route path="/home/addhome" element={<AddHome />} />
        <Route path="/about/addabout" element={<AddAbout />} />
        <Route path="/projects/addproject" element={<AddProject />} />
        <Route path="/enquiry/addenquiry" element={<AddEnquiry />} />
        <Route
          path="/testimonial/addtestimonial"
          element={<AddTestimonial />}
        />
        <Route path="/employee/addemployee" element={<AddEmployee />} />
        <Route path="/contact/addcontact" element={<AddContact />} />

        {/* Yashila add Routes */}
        <Route
          path="/yashilagetintouch/addyashilagetintouch"
          element={<AddYashilaGetintouch />}
        />
        <Route
          path="/yashilagetintouchinfo/addyashilagetintouchinfo"
          element={<AddYashilaGetintouchinfo />}
        />
        <Route
          path="/yashilagallery/addyashilagallery"
          element={<AddYashilaGallery />}
        />
        <Route
          path="/yashilavideo/addyashilavideo"
          element={<AddYashilaVideo />}
        />
        <Route
          path="/yashilacontact/addyashilacontact"
          element={<AddYashilaContact />}
        />
        <Route
          path="/yashilaabout-bivab/addyashilaabout-bivab"
          element={<AddYashilaAboutBivab />}
        />
        <Route
          path="/yashilaabout-bivab-owner/addyashilaabout-bivab-owner"
          element={<AddYashilaAboutOwners />}
        />
        <Route
          path="/yashilaabout-bivab-commercial/addyashilaabout-bivab-commercial"
          element={<AddYashilaAboutCommercial />}
        />
        <Route
          path="/yashilaabout-mision/addyashilaabout-mision"
          element={<AddYashilaAboutMision />}
        />
        <Route
          path="/yashilaabout-vision/addyashilaabout-vision"
          element={<AddYashilaAboutVision />}
        />
        <Route
          path="/yashila-count-up/addyashila-count-up"
          element={<AddYashilaCountUp />}
        />
        <Route
          path="/yashilaproperties/addyashilaproperties"
          element={<AddYashilaProperties />}
        />
      </Routes>
    </BrowserRouter>
  );
}

export default Routing;
