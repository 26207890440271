import { useEffect, useState } from "react";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";

export default function AddYashilaProperties() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px auto" width="90%">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Yashila Properties
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box width="70%">
              <Card variant="outlined">
                <CardContent style={{ border: "1px solid gray" }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    Add Details
                  </Typography>
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <TextField
                          id="ID"
                          label="id"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="Name"
                          label="name"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="Location"
                          label="location"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="price"
                          label="Price"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="Price Per Sqft"
                          label="price_per_sqft"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="Size"
                          label="size"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="rooms"
                          label="Rooms"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          id="Description"
                          label="description"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Img1"
                          id="inputGroupFile01"
                          type="file"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Img2"
                          id="inputGroupFile01"
                          type="file"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Img3"
                          id="inputGroupFile01"
                          type="file"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button variant="contained" color="error" fullWidth>
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
