import { useEffect, useState } from "react";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";

import { POSTNETWORK } from "../../../utils/network";
import ApiUrl from "../../../utils/url";
import { useNavigate } from "react-router-dom";

export default function AddYashilaCountUp() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const [title, setTitle] = useState("");
  const [number, setNumber] = useState(null);

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      title: title,
      number: number,
      isActive: true,
    };
    try {
      const formDataResponse = await POSTNETWORK(ApiUrl.COUNT_CREATE, formData);
      if (formDataResponse.status) {
        navigate("/yashila-count-up");
      }
      console.log(formData);
    } catch (error) {
      console.error("Error while submitting form:", error);
    }
  };

  const handleNumberChange = (e) => {
    const value = parseInt(e.target.value);
    setNumber(value);
  };

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px" width="50vw">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Yashila Sucess Countup
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex" }}>
            <Box width="100%">
              <Card variant="outlined" sx={{ marginLeft: "250px" }}>
                <CardContent sx={{ border: "1px solid gray" }}>
                  <h5 className="card-title text-center">Add Details</h5>
                  <br />
                  <br />
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="number"
                          label="number"
                          variant="outlined"
                          fullWidth
                          inputProps={{
                            type: "number",
                            inputMode: "numeric",
                            pattern: "[0-9]*", // Optional, adds browser validation
                          }}
                          value={number || ""}
                          onChange={handleNumberChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="title"
                          label="title"
                          variant="outlined"
                          fullWidth
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                        />
                      </Grid>

                      <Grid item xs={12} my={4}>
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={handleFormSubmit}
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
