import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Grid,
  Modal,
  IconButton,
} from "@mui/material";
import { mockDataHome } from "../../data/mockData";
import { Edit, Delete, Close } from "@mui/icons-material";
import DataTable from "react-data-table-component";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { useNavigate } from "react-router-dom";
const Home = () => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(mockDataHome);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [formData, setFormData] = useState({
    id: "",

    image: "",
    title: "",
  });
  const handleAdd = () => {
    setIsAddModalOpen(true);
  };

  const editForm = (id) => {
    setSelectedItemId(id);
    setIsEditModalOpen(true);
    const selectedItem = mockDataHome.find((item) => item.id === id);
    setFormData({
      serialNumber: selectedItem.serialNumber,
      image: selectedItem.image,
      title: selectedItem.title,
    });
  };

  const closeModal = () => {
    setIsEditModalOpen(false);
    setIsAddModalOpen(false);
    setFormData({
      id: "",

      image: "",
      title: "",
    });
  };

  const deleteData = (id) => {
    console.log("Deleting item with ID:", id);
    const updatedData = filteredData.filter((item) => item.id !== id);
    setFilteredData(updatedData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`/assets/${row.imageName}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },

    {
      name: "Access Level",
      cell: (row) => (
        <Grid container spacing={1}>
          <Grid item>
            <IconButton
              onClick={() => editForm(row.id)}
              style={customStyles.editButton}
            >
              <Edit />
            </IconButton>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() => deleteData(row.id)}
              style={customStyles.deleteButton}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Home"
          subtitle="You can add or modify home page details"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAdd}>
              ADD NEW DETAILS
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box width="74vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen || isAddModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="h2">
              {isEditModalOpen ? "Edit Form" : "Add New Details"}
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  id="title"
                  label="Title"
                  variant="outlined"
                  value={formData.title}
                  onChange={handleChange}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  id="image"
                  label="Image"
                  fullWidth
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={handleImageChange}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                {isEditModalOpen ? "Update" : "Submit"}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default Home;
