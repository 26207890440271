import { useEffect, useState } from "react";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";

export default function AddYashilaGetintouch() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px" width="70vw">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Yashila Get In Touch
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex" }}>
            <Box width="70vw">
              <Card variant="outlined" sx={{ marginLeft: "60px" }}>
                <CardContent sx={{ border: "1px solid gray" }}>
                  <h5 class="card-title text-center">Add Details</h5>
                  <br />
                  <br />
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          label="Name"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="phone"
                          label="Phone"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="message"
                          label="Message"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button variant="contained" color="error" fullWidth>
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
