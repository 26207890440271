import { useEffect, useState } from "react";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import TestimonialMobile from "../../../components/reusable/mobile/TestimonialMobile";

export default function AddTestimonial() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px" width="70vw">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            TESTIMONIAL
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex" }}>
            <Box width="70vw">
              <Card variant="outlined">
                <CardContent style={{ border: "1px solid gray" }}>
                  <h6 class="card-title text-center">Add Details</h6>
                  <br />
                  <br />
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="ID"
                          label="ID"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="name"
                          label="Name"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="email"
                          label="Email"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="description"
                          label="Description"
                          variant="outlined"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button variant="contained" color="error" fullWidth>
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "39vw",
                marginLeft: "30px",
              }}
            >
              <Box
                sx={{
                  height: "90vh",
                  border: "1px solid",
                  padding: "0px 10px",
                  paddingTop: "57px",
                  borderRadius: "30px",
                  backgroundColor: colors.black[200],
                }}
              >
                <TestimonialMobile />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
