import React from 'react'
import { useRef } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../constants/theme";
import { mockDataAbout } from '../../../data/mockData';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';


const AboutMobile = () => {
    const contentRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


    return (
        <Box sx={{ height: '76.5vh', width: '22.22vw', border: `3px solid ${colors.black[100]}`, borderRadius: '8px', backgroundColor: colors.white[100] }}>
            <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
                <Box ref={contentRef} sx={{ height: '800px', position: 'relative' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="2px 11px" marginBottom="3px" sx={{ backgroundColor: colors.blue[100] }}>
                        <Typography
                            variant="h4"
                            color={colors.white[100]}
                            fontWeight="bold"
                            sx={{ m: "10px 0 0 0", marginTop: '-5px' }}
                        >
                            About Us
                        </Typography>
                        <img
                            alt="profile-user"
                            width="80px"
                            height="50px"
                            src={`/assets/profile-logo.png`}
                            style={{ cursor: "pointer", filter: 'grayscale(1) contrast(0) brightness(1000)' }}
                        />
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="center">
                        <img
                            alt="profile-user"
                            width="326px"
                            height="200px"
                            src={"/assets/aboutbg.jpg"}
                            style={{ cursor: "pointer" }}
                        />
                    </Box>
                    {mockDataAbout.map((item) => (
                        <Box key={item.id}>
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: '29px' }}>
                                <img
                                    alt="profile-user"
                                    width="234px"
                                    height="200px"
                                    src={`/assets/${item.image}`}
                                    style={{ cursor: "pointer" }}
                                />
                                <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'center', width: '234px', backgroundColor: colors.greenAccent[500] }}>
                                    <Typography
                                        variant="h6"
                                        color={colors.white[100]}
                                        fontWeight="bold"
                                    >
                                        {item.name}
                                    </Typography>
                                    <Typography
                                        variant="h6"
                                        color={colors.white[100]}
                                        fontWeight="bold"
                                    >
                                        {item.title}
                                    </Typography>
                                </Box>
                            </Box>

                            <Box sx={{ textAlign: 'center' }}>
                                <Typography
                                    variant="h5"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0", textAlign: 'center', padding: '0px 25px' }}
                                >
                                    ABOUT BIVAB
                                </Typography>
                                <Typography
                                    variant="h6"
                                    color={colors.grey[100]}
                                    fontWeight="400"
                                    sx={{ m: "10px 0 0 0", padding: '0px 10px' }}
                                >
                                    {item.description}
                                </Typography>
                            </Box>
                        </Box>
                    ))}

                    <Box display="flex" justifyContent="center" alignItems="center" marginTop="25px" height="70px" sx={{ backgroundColor: colors.black[100] }}>
                        <Typography
                            variant="h5"
                            fontWeight="bold"
                            sx={{ color: colors.white[100], marginRight: '15px' }}
                        >
                            Also contact us in
                        </Typography>
                        <WhatsAppIcon sx={{ color: 'white', backgroundColor: '#21d921', width: '36px', height: '38px', borderRadius: '26px' }} />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default AboutMobile