import { useState, useEffect } from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Paper,
} from "@mui/material"; // Import Paper from MUI
import { tokens } from "../../constants/theme";
import { Link } from "react-router-dom";

// Import icons from MUI
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import SpaceDashboardOutlinedIcon from "@mui/icons-material/SpaceDashboardOutlined";

import { BsPerson } from "react-icons/bs";
import { MdOutlineManageAccounts } from "react-icons/md";
import { PiContactlessPaymentLight } from "react-icons/pi";
import { SiMicrosoftteams } from "react-icons/si";
import { GrBlog } from "react-icons/gr";
import { FaRegNoteSticky } from "react-icons/fa6";
import { PiNewspaperClipping } from "react-icons/pi";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import { GrProjects } from "react-icons/gr";
import { GrStatusUnknown } from "react-icons/gr";
import { GoQuestion } from "react-icons/go";
import { BsDatabaseAdd } from "react-icons/bs";
import { FiGitPullRequest } from "react-icons/fi";
import { TbPhotoPlus } from "react-icons/tb";
import { HiOutlineVideoCamera } from "react-icons/hi2";
import { PiTargetFill } from "react-icons/pi";
import { GiEyeTarget } from "react-icons/gi";
import { RiContactsLine } from "react-icons/ri";
import { MdOutlineAlignHorizontalRight } from "react-icons/md";
import { BiCarousel } from "react-icons/bi";

const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => setSelected(title)}
      icon={icon}
    >
      <Typography>{title}</Typography>
      <Link to={to} />
    </MenuItem>
  );
};

const Sidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Paper
      sx={{
        // Wrap the sidebar in Paper component for elevation and styling
        position: "sticky",
        top: 0,
        height: "100vh", // Set the height to full viewport height
        backgroundColor: colors.primary[400], // Set background color
        zIndex: theme.zIndex.appBar + 1, // Set zIndex to keep it above the app bar
      }}
    >
      {domLoaded && (
        <Box
          sx={{
            "& .pro-sidebar-inner": {
              background: `${colors.primary[400]} !important`,
            },
            "& .pro-icon-wrapper": {
              backgroundColor: "transparent !important",
            },
            "& .pro-inner-item": {
              padding: "5px 23px 5px 20px !important",
            },
            "& .pro-inner-item:hover": {
              color: "#be1719 !important",
            },
            "& .pro-menu-item.active": {
              color: "black !important",
            },
          }}
        >
          <ProSidebar collapsed={isCollapsed} style={{height:'100vh', width:'88px'}}>
            <Menu iconShape="square">
              {/* LOGO AND MENU ICON */}
              <MenuItem
                onClick={() => setIsCollapsed(!isCollapsed)}
                style={{
                  margin: "10px 0 20px 0",
                  color: colors.grey[100],
                  
                }}
              >
                <Box sx={{display:'flex', justifyContent:'space-between'}}>
                {!isCollapsed && (
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    ml="15px"
                  >
                    <Typography variant="h5" color={colors.grey[100]}>
                      ADMINIS
                    </Typography>
                  </Box>
                )}
                <IconButton onClick={() => setIsCollapsed(!isCollapsed)}>
                  <MenuOutlinedIcon/>
                </IconButton>
                </Box>
                
              </MenuItem>

              {!isCollapsed && (
                <Box mb="25px">
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                  >
                    {/* Adjust image source as per your project structure */}
                    <img
                      alt="profile-user"
                      width="100px"
                      height="100px"
                      src={`/assets/profile-logo.png`}
                      style={{
                        cursor: "pointer",
                        borderRadius: "50%",
                        objectFit: "contain",
                      }}
                    />
                  </Box>
                  <Box textAlign="center">
                    <Typography
                      variant="h5"
                      color={colors.grey[100]}
                      fontWeight="bold"
                      sx={{ m: "10px 0 0 0" }}
                    >
                      BIVAB
                    </Typography>
                    <Typography variant="h6" color={colors.greenAccent[500]}>
                      Admin
                    </Typography>
                  </Box>
                </Box>
              )}

              {/* MENU ITEMS */}
              <Box
                paddingLeft={isCollapsed ? undefined : "10%"}
                sx={{ color: "black" }}
              >
                <Item
                  title="Dashboard"
                  to="/dashboard"
                  icon={<SpaceDashboardOutlinedIcon />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </Box>

              {/* Employ Management */}
              <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'black', m: "15px 0 5px 0px" }}>
                <SubMenu title={
                  <Typography variant="h6" sx={{ fontWeight: '600', color: 'black', m: "15px 0 5px 0px", fontSize:'14px' }}>
                    USER
                  </Typography>
                } icon={<BsPerson style={{fontSize:'large'}}/>} color={colors.black[100]} >


                  <Item title="Manage User"
                    to="/bivabmanageuser"
                    icon={<MdOutlineManageAccounts style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              </Typography>

              {/* BIVAB DEVELOPERS */}
              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "black", m: "15px 0 5px 0px" }}
              >
                <SubMenu
                  title={
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "black", fontSize:'14px' }}
                    >
                      DEVELOPER CRMS
                    </Typography>
                  }
                  icon={<MdOutlineAlignHorizontalRight style={{fontSize:'large'}}/>}
                >
                  <Item
                    title="Home Carousel"
                    to="/herohomecarousel"
                    icon={<BiCarousel style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />

                  <Item
                    title="Get In Touch"
                    to="/getintouch"
                    icon={<PiContactlessPaymentLight style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />

                  {/* <Item title="About Owner"
                    to="/owner"
                    icon={<InfoOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  /> */}
                  <Item
                    title="Mision"
                    to="/mision"
                    icon={<PiTargetFill style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Vision"
                    to="/vision"
                    icon={<GiEyeTarget style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  {/* <Item
                    title="Resolve Enquiry"
                    to="/resolveenquiry"
                    icon={<PiNewspaperClipping style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Unresolve Enquiry"
                    to="/enquirynow"
                    icon={<PiNewspaperClipping style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  /> */}
                  <Item
                    title="Blog"
                    to="/blog"
                    icon={<GrBlog style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="About Team"
                    to="/team"
                    icon={<SiMicrosoftteams style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Qna Carousel"
                    to="/carousel"
                    icon={<GoQuestion style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />

                  <Item
                    title="Testimonials"
                    to="/testimonial"
                    icon={<FaRegNoteSticky style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Contact Logs"
                    to="/contacts"
                    icon={<BsDatabaseAdd style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Career"
                    to="/career"
                    icon={<FaRegEnvelopeOpen style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Projects"
                    to="/projects"
                    icon={<GrProjects style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Know More"
                    to="/knowmore"
                    icon={<GrStatusUnknown style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </SubMenu>
              </Typography>
              {/* YASHILA CRMS SIDEBAR */}

              <Typography
                variant="h6"
                sx={{ fontWeight: "bold", color: "black", m: "15px 0 5px 0px" }}
              >
                <SubMenu
                  title={
                    <Typography
                      variant="h6"
                      sx={{ fontWeight: "bold", color: "black", fontSize:'14px' }}
                    >
                      YASHILA CRMS
                    </Typography>
                  }
                  icon={<MdOutlineAlignHorizontalRight style={{fontSize:'large'}}/>}
                >
                  {/* <Item title="Get In Touch"
                    to="/yashilagetintouch"
                    icon={<CallIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  /> */}
                  <Item
                    title="Get In Touch (INFO)"
                    to="/yashilagetintouchinfo"
                    icon={<PiContactlessPaymentLight style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="About Us"
                    to="/yashilaabout"
                    icon={<FiGitPullRequest style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Mision & Vision"
                    to="/yashilamision"
                    icon={<GiEyeTarget style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Photo Gallery"
                    to="/yashilagallery"
                    icon={<TbPhotoPlus style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Our Video"
                    to="/yashilavideo"
                    icon={<HiOutlineVideoCamera style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  <Item
                    title="Contact Us"
                    to="/yashilacontact"
                    icon={<RiContactsLine style={{fontSize:'large'}}/>}
                    selected={selected}
                    setSelected={setSelected}
                  />
                  {/* <Item title="Our Properties"
                    to="/yashilaproperties"
                    icon={<HomeOutlinedIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  /> */}
                </SubMenu>
              </Typography>
            </Menu>
          </ProSidebar>
        </Box>
      )}
    </Paper>
  );
};

export default Sidebar;
