// apiConfig.js

class ApiUrl {
    //  static API = "https://api.bivabyashila.com/";
    static API_BASE_URL = "https://api.bivabyashila.com/bivabs/";
    static ImageHostURl = "https://bucket.bivabyashila.com/cloudemedia/";

    static APIYSAHLA_URL = `${ApiUrl.API_BASE_URL}yashla/`;
    static APIBIVDEV_URL = `${ApiUrl.API_BASE_URL}BivabDevelopers/`;
    static APIDASHBOARD_URL = `${ApiUrl.API_BASE_URL}dashboard/`;


    //Hero Carousel
    static Hero_GET_ALL = `${ApiUrl.APIBIVDEV_URL}getAllDevHome`;
    static HERO_GET_BY_ID = `${ApiUrl.APIBIVDEV_URL}getDevHomeById/`;
    static HERO_CREATE = `${ApiUrl.APIBIVDEV_URL}saveDevHomeInfo`;
    static HERO_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteHomeById/`;


    // DASHBOARD ADMIN
    static ADMIN_REGISTRATION = `${ApiUrl.APIDASHBOARD_URL}registerAdmin`;
    static ADMIN_LOGIN = `${ApiUrl.APIDASHBOARD_URL}loginAdmin`;
    static ADMIN_UPDATEBYID = `${ApiUrl.APIDASHBOARD_URL}updateAdmin/`;
    static ADMIN_GETALLADMIN = `${ApiUrl.APIDASHBOARD_URL}getAllAdmin`;
    static ADMIN_GETADMINBYID = `${ApiUrl.APIDASHBOARD_URL}getAdminById/`;
    static ADMIN_DELETEADMINBYID = `${ApiUrl.APIDASHBOARD_URL}deleteAdmin/`;


    // USER LOGIN AND REG.
    static LOGIN_URL = `${ApiUrl.API_BASE_URL}/createContact`;
    static CREATE_CONTACT = `${ApiUrl.API_BASE_URL}createContact`;
    // YASHILA URL
    static GETINTOUCH_GETALL = `${ApiUrl.APIYSAHLA_URL}GetInTouchAll`;
    static GETINTOUCH_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteGetInTouchById`;
    static GETINTOUCH_PUT = `${ApiUrl.APIYSAHLA_URL}updateGetInTouchById`;
    static GETINTOUCH_POST = `${ApiUrl.APIYSAHLA_URL}GetInTouchCreate`;
    static GETINTOUCH_GETBYID = `${ApiUrl.APIYSAHLA_URL}GetInTouchGetbyId`;

    // YASHILA Mission and Vision
    // static CreateMissionVision = `${ApiUrl.APIYSAHLA_URL}createMissionVision`;
    // static GetAllAboutMissionVision = `${ApiUrl.APIYSAHLA_URL}getAllAboutMissionVision`;
    // static GetAllAboutMission = `${ApiUrl.APIYSAHLA_URL}getAllMission`;
    // static GetAllAboutVision = `${ApiUrl.APIYSAHLA_URL}getAboutVision`;
    // static GetAboutMissionVisionById = `${ApiUrl.APIYSAHLA_URL}getAboutMissionVisionById`;
    // static UpdateMissionVisionById = `${ApiUrl.APIYSAHLA_URL}updateMissionVisionById`;
    // static DeleteAboutMissionVisionById = `${ApiUrl.APIYSAHLA_URL}deleteAboutMissionVisionById`;

    // YASHILA CONTACT
    static CONTACT_GETALL = `${ApiUrl.APIYSAHLA_URL}getallContacts`;
    static CONTACT_POST = `${ApiUrl.APIYSAHLA_URL}createContacts`;
    static CONTACT_GETBYID = `${ApiUrl.APIYSAHLA_URL}getContactById`;
    static CONTACT_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteContacts`;
    static CONTACT_PUT = `${ApiUrl.APIYSAHLA_URL}updateContactsById`;

    // VIDEO
    static VIDEO_GETALL = `${ApiUrl.APIYSAHLA_URL}getAllVideo`;
    static VIDEO_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteVideoById`;
    static VIDEO_POST = `${ApiUrl.APIYSAHLA_URL}createOurVideo`;
    static VIDEO_GETBYID = `${ApiUrl.APIYSAHLA_URL}getVideoById`;
    static VIDEO_PUT = `${ApiUrl.APIYSAHLA_URL}updateVideoById`;

    // COUNT UP
    static COUNTUP_GETALL = `${ApiUrl.APIYSAHLA_URL}getAllYashlaSuccessCountUp`;
    static COUNT_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteSuccess`;
    static COUNT_CREATE = `${ApiUrl.APIYSAHLA_URL}createYashlaSuccessCountUp`;
    static COUNT_UPDATE = `${ApiUrl.APIYSAHLA_URL}updateSuccess`;
    static COUNT_GETBYID = `${ApiUrl.APIYSAHLA_URL}getSuccessById`;
    //ABOUT OWNER
    static OWNER_GETALL = `${ApiUrl.APIYSAHLA_URL}getAllAboutOwner`;
    static OWNER_POST = `${ApiUrl.APIYSAHLA_URL}createAboutOwner`;
    static OWNER_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteYashlaAboutOwners`;
    static OWNER_GETBYID = `${ApiUrl.APIYSAHLA_URL}getByIdOwner/`;
    static OWNER_UPDATE = `${ApiUrl.APIYSAHLA_URL}updateYashlaAboutOwners/`;

    //GALLERY
    static GALLERY_GETALL = `${ApiUrl.APIYSAHLA_URL}getAllGallery`;
    static GALLERY_POST = `${ApiUrl.APIYSAHLA_URL}saveYashlaGallery`;
    static GALLERY_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteById`;
    static GALLERY_GETBYID = `${ApiUrl.APIYSAHLA_URL}yashla/getGalleryById`;
    // ABOUT COMMERCIAL
    static COMMERCIAL_GETALL = `${ApiUrl.APIYSAHLA_URL}getAllAboutBivabCommercial`;
    static COMMERCIAL_POST = `${ApiUrl.APIYSAHLA_URL}createAboutBivabCommercial`;
    static COMMERCIAL_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteByIdAboutBivabCommercial`;
    static COMMERCIAL_GETBYID = `${ApiUrl.APIYSAHLA_URL}getByIdAboutBivabCommercial`;
    static COMMERCIAL_PUT = `${ApiUrl.APIYSAHLA_URL}updateByIdAboutBivabCommercial`;
    static YashilaVideo = `${ApiUrl.APIYSAHLA_URL}getAllVideo`;
    static CreateYashilaVideo = `${ApiUrl.APIYSAHLA_URL}createOurVideo`;

    // Bivab Developers Mission And Vision
    static BIVABDEV_MISSION_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllMission`;
    static BIVABDEV_VISSION_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllVision`;
    static BIVABDEV_MISSION_VISSION_ADD = `${ApiUrl.APIBIVDEV_URL}createMissionVision`;
    static BIVABDEV_MISSION_VISSION_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateMissionOrVisionById/`;
    static BIVABDEV_MISSION_VISSION_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteMissionVisionByid/`;
    static BIVABDEV_MISSION_VISSION_GETBYID = `${ApiUrl.APIBIVDEV_URL}getMissionOrVisionByID/`;

    // Bivab Yashila Mission And Vision
    static BIVABYASHILA_MISSION_GETALL = `${ApiUrl.APIYSAHLA_URL}getAllMission`;
    static BIVABYASHILA_VISSION_GETALL = `${ApiUrl.APIYSAHLA_URL}getAllVision`;
    static BIVABYASHILA_MISSION_VISSION_ADD = `${ApiUrl.APIYSAHLA_URL}createMissionVision`;
    static BIVABYASHILA_MISSION_VISSION_UPDATE = `${ApiUrl.APIYSAHLA_URL}updateMissionOrVisionById/`;
    static BIVABYASHILA_MISSION_VISSION_DELETE = `${ApiUrl.APIYSAHLA_URL}deleteMissionVisionByid/`;
    static BIVABYASHILA_MISSION_VISSION_GETBYID = `${ApiUrl.APIYSAHLA_URL}getMissionOrVisionByID/`;


    //GET IN TOUCH
    static BIVABDEV_GETINTOUCH_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllGetInTouchBivabs`;
    static BIVABDEV_GETINTOUCH_POST = `${ApiUrl.APIBIVDEV_URL}createGetInTouchBivabs`;
    static BIVABDEV_GETINTOUCH_GETBYID = `${ApiUrl.APIBIVDEV_URL}GetInTouchBivabsgetById`;
    static BIVABDEV_GETINTOUCH_PUT = `${ApiUrl.APIBIVDEV_URL}updateGetInTouchBivabs`;
    static BIVABDEV_GETINTOUCH_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteGetInTouchById`;

    static BIVABDEV_ENQ_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllEnquiry`;
    static BIVABDEV_ENQ_UPDATESTS = `${ApiUrl.APIBIVDEV_URL}updateByIdEnquiry`;
    static BIVABDEV_ENQ_GETALLRESOLVE = `${ApiUrl.APIBIVDEV_URL}getAllResolveEnq`;


    // QNA Carousel Developers
    static BIVABDEV_QNACARAZOL_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllQnaCarousel`;
    static BIVABDEV_QNACARAZOL_CREATE = `${ApiUrl.APIBIVDEV_URL}saveQnaCarousel`;
    static BIVABDEV_QNACARAZOL_GETBYID = `${ApiUrl.APIBIVDEV_URL}getAllQnaCarouselById/`;
    static BIVABDEV_QNACARAZOL_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateQnaCarouselById/`;
    static BIVABDEV_QNACARAZOL_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteQnaCarouselById/`;



    static BIVABDEV_GETALLBLOG = `${ApiUrl.APIBIVDEV_URL}getAllBlog`;

    // Bivab Visio
    static BIVABDEV_VISION_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllVision`;

    //Bivab About Owner
    static BIVABDEV_OWNER_CREATE = `${ApiUrl.APIBIVDEV_URL}createAboutOwnerBivabDev`;
    static BIVABDEV_OWNER_GETBYID = `${ApiUrl.APIBIVDEV_URL}getAboutOwnerBivabsDevById/`;
    static BIVABDEV_OWNER_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateAboutOwners/`;
    static BIVABDEV_OWNER_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllAboutOwnerBivabsDev`;
    static BIVABDEV_OWNER_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteAboutOwnersDev/`;


    //KNOW MORE
    static BIVABDEV_KNOWMORE_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllKnowMore`;
    static BIVABDEV_KNOWMORE_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteKnowMore/`;
    static BIVABDEV_KNOWMORE_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateKnowMore/`;
    static BIVABDEV_KNOWMORE_CREATE = `${ApiUrl.APIBIVDEV_URL}createKnowMore`;
    static BIVABDEV_KNOWMORE_GETBYID = `${ApiUrl.APIBIVDEV_URL}getKnowMoreById/`;


    //CAREER
    static BIVABDEV_CAREER_CREATE = `${ApiUrl.APIBIVDEV_URL}createCareer`;
    static BIVABDEV_CAREER_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllCareer`;
    static BIVABDEV_CAREER_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateCareer`;
    static BIVABDEV_CAREER_GETBYID = `${ApiUrl.APIBIVDEV_URL}getByIdCareer/`;
    static BIVABDEV_CAREER_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteCareer/`;

    //CONTACT
    static BIVABDEV_CONTACT_CREATE = `${ApiUrl.APIBIVDEV_URL}createContactLog`;
    static BIVABDEV_CONTACT_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllContacatLog`;
    static BIVABDEV_CONTACT_GETBYID = `${ApiUrl.APIBIVDEV_URL}getContactLogById/`;
    static BIVABDEV_CONTACT_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateContactLogById/`;
    static BIVABDEV_CONTACT_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteContactLogById/`;

    //TEAM
    static BIVABDEV_TEAM_GETALL = `${ApiUrl.APIBIVDEV_URL}getallAboutTeam`;
    static BIVABDEV_TEAM_CREATE = `${ApiUrl.APIBIVDEV_URL}createAboutTeam`;
    static BIVABDEV_TEAM_GETBYID = `${ApiUrl.APIBIVDEV_URL}getAboutTeamById/`;
    static BIVABDEV_TEAM_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateAboutTeam/`;
    static BIVABDEV_TEAM_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteAboutTeam/`;

    //TESTIMONIAL
    static BIVABDEV_TESTIMONIAL_CREATE = `${ApiUrl.APIBIVDEV_URL}saveTestimonialBivabsDev`;
    static BIVABDEV_TESTIMONIAL_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateTestimonialBivabsDevById/`;
    static BIVABDEV_TESTIMONIAL_GETBYID = `${ApiUrl.APIBIVDEV_URL}getTestimonialBivabsDevById/`;
    static BIVABDEV_TESTIMONIAL_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllTestimonialBivabsDev`;
    static BIVABDEV_TESTIMONIAL_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteTestimonialBivabsDevById/`;

    //PROJECTS
    static BIVABDEV_PROJECT_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllProject`;
    static BIVABDEV_PROJECT_GETBYID = `${ApiUrl.APIBIVDEV_URL}getProjectByID`;
    static BIVABDEV_PROJECT_CREATE = `${ApiUrl.APIBIVDEV_URL}createProject`;
    static BIVABDEV_PROJECT_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteProjectById/`;

    //BLOG
    static BIVABDEV_BLOG_CREATE = `${ApiUrl.APIBIVDEV_URL}createBlog`;
    static BIVABDEV_BLOG_GETBYID = `${ApiUrl.APIBIVDEV_URL}getBlogById/`;
    static BIVABDEV_BLOG_UPDATE = `${ApiUrl.APIBIVDEV_URL}updateById/`;
    static BIVABDEV_BLOG_DELETE = `${ApiUrl.APIBIVDEV_URL}deleteById/`;
    static BIVABDEV_BLOG_GETALL = `${ApiUrl.APIBIVDEV_URL}getAllAboutOwnerBivabsDev`;

}

export default ApiUrl;