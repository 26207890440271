import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Modal,
  IconButton,
  TextField,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../constants/theme";
import { mockDataUserAnswer } from "../../data/mockData";
import { useTheme } from "@mui/material";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const About = () => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(mockDataUserAnswer);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const deleteData = (id) => {
    console.log("Deleting item with ID:", id);
  };
  const handleImageChange = (e) => {};
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Answer 1",
      selector: (row) => row.answer1,
      sortable: true,
    },
    {
      name: "Answer 2",
      selector: (row) => row.answer2,
      sortable: true,
    },
    {
      name: "Answer 3",
      selector: (row) => row.answer3,
      sortable: true,
    },
    {
      name: "Answer 4",
      selector: (row) => row.answer4,
      sortable: true,
    },
    {
      name: "Answer 5",
      selector: (row) => row.answer5,
      sortable: true,
    },
    {
      name: "Answer 6",
      selector: (row) => row.answer6,
      sortable: true,
    },
    {
      name: "Answer 7",
      selector: (row) => row.answer7,
      sortable: true,
    },
    {
      name: "Answer 8",
      selector: (row) => row.answer8,
      sortable: true,
    },
    {
      name: "Answer 9",
      selector: (row) => row.answer9,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header title="ANSWERS" subtitle="List of user answers" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}></Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};

export default About;
