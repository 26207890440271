import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { MdOutlineManageAccounts } from "react-icons/md";
import { SiMicrosoftteams } from "react-icons/si";
import { GrBlog } from "react-icons/gr";
import { FaRegNoteSticky } from "react-icons/fa6";
import { PiNewspaperClipping } from "react-icons/pi";
import { FaRegEnvelopeOpen } from "react-icons/fa6";
import { GrProjects } from "react-icons/gr";
import { GrStatusUnknown } from "react-icons/gr";

const cardsData = [
  { title: "Admins", icon: <MdOutlineManageAccounts icon="Admins" />, link: "/bivabmanageuser" },
  { title: "Team", icon: <SiMicrosoftteams icon="Team" />, link: "/team" },
  { title: "Blogs", icon: <GrBlog icon="Blogs" />, link: "/blog" },
  { title: "Testimonials", icon: <FaRegNoteSticky icon="Testimonials" />, link: "/testimonial" },
  { title: "Enquiry", icon: <PiNewspaperClipping icon="Enquiries" />, link: "/enquirynow" },
  { title: "Career", icon: <FaRegEnvelopeOpen icon="Careers" />, link: "/career" },
  { title: "Projects", icon: <GrProjects icon="Projects" />, link: "/projects" },
  { title: "Knowmore", icon: <GrStatusUnknown icon="Knowmore" />, link: "/knowmore" },
];

const GridCards = () => {
  return (
    <Grid container spacing={2}>
      {cardsData.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Link to={card.link} style={{ textDecoration: "none" }}>
            {" "}
            {/* Wrap each card in a Link component */}
            <Card
              sx={{ backgroundColor: "#00000014", boxShadow: 1 }}
            >
              <CardContent sx={{ padding: 2, display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginBottom: 1, fontSize:'28px', fontWeight:'400', color:'#5f5f5f' }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginBottom: 1, fontSize:'42px', fontWeight:'400', color:'#961718' }}
                >
                  {card.icon}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default GridCards;
