import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  styled,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { mockDataBivabBlog } from "../../data/mockData";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
import {
  GETNETWORK,
  DELETENETWORK,
  PUTNETWORK,
  POSTNETWORK,
} from "../../utils/network";
import ApiUrl from "../../utils/url";
import Login from "../public/Login";

const ContactLog = () => {
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    address: "",
    phoneNumber: "",
    email: "",
    twitterLink: "",
    instagramLink: "",
    facebookLink: "",
  });

  const [address, setAddress] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [twitterLink, setTwitterLink] = useState("");
  const [instagramLink, setInstagramLink] = useState("");
  const [facebookLink, setFacebookLink] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();

    const loginPayload = {
      address: address,
      phoneNumber: phoneNumber,
      email: email,
      twitterLink: twitterLink,
      instagramLink: instagramLink,
      facebookLink: facebookLink,
    };

    try {
      const loginResponse = await POSTNETWORK(
        ApiUrl.BIVABDEV_CONTACT_CREATE,
        loginPayload
      );
      if (loginResponse.status) {
        console.log("Login successful:", loginResponse);
        closeModal();
        window.location.reload();
      } else {
        console.error("Login failed. No token in the response.");
      }
    } catch (error) {
      console.error("Error during login:", error);
    }

    setAddress("");
    setPhoneNumber("");
    setEmail("");
    setTwitterLink("");
    setInstagramLink("");
    setFacebookLink("");
  };

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_CONTACT_GETALL);
    console.log(response);
    setData(response.data);
  };

  const handleEditedSubmit = async (e) => {
    console.log("formData", formData);
    e.preventDefault();
    try {
      const FinalEditResponse = await PUTNETWORK(
        `${ApiUrl.BIVABDEV_CONTACT_UPDATE}${formData.id}`,
        formData
      );
      console.log("FinalEditResponse", FinalEditResponse);
      fetchData();
      closeModal();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const editForm = async (id) => {
    try {
      const response = await GETNETWORK(
        `${ApiUrl.BIVABDEV_CONTACT_GETBYID}${id}`
      );
      const responseData = response.data;
      console.log("responsedata", responseData);
      setFormData({
        id: responseData.id,
        address: responseData.address,
        phoneNumber: responseData.phoneNumber,
        email: responseData.email,
        twitterLink: responseData.twitterLink,
        instagramLink: responseData.instagramLink,
        facebookLink: responseData.facebookLink,
      });
      setIsEditModalOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const deleteData = async (id) => {
    const responses = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_CONTACT_DELETE}${id}`
    );
    if (responses.status) {
      fetchData();
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setFormData({
      id: "",
      address: "",
      phoneNumber: "",
      email: "",
      twitterLink: "",
      instagramLink: "",
      facebookLink: "",
    });
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Address",
      selector: (row) => row.address,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phoneNumber,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Twiter",
      selector: (row) => row.twitterLink,
      sortable: true,
    },
    {
      name: "Instagram",
      selector: (row) => row.instagramLink,
      sortable: true,
    },
    {
      name: "Facebook",
      selector: (row) => row.facebookLink,
      sortable: true,
    },

    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => editForm(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  if(!localStorage.getItem('email') && !localStorage.getItem('password'))  return <Login/>

  return (
    <Layout>
      <Box m="20px">
        <Header title="Contact Log" subtitle="List of contact details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAddModalOpen} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW CONTACT
            </Button>
          </Box>
        </Box>
        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">
                Add New Details
              </Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="address"
                    label="Address"
                    fullWidth
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="phoneNumber"
                    label="Phone"
                    fullWidth
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="email"
                    label="Email"
                    fullWidth
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="twitterLink"
                    label="Twiter"
                    fullWidth
                    onChange={(e) => setTwitterLink(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="instagramLink"
                    label="Instagram"
                    fullWidth
                    onChange={(e) => setInstagramLink(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="facebookLink"
                    label="Facebook"
                    fullWidth
                    onChange={(e) => setFacebookLink(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" onClick={closeModal} color="error">
                  CANCEL
                </Button>
                <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="h2">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleEditedSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="address"
                  label="Address"
                  fullWidth
                  value={formData.address}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="phoneNumber"
                  label="Phone"
                  fullWidth
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="email"
                  label="Email"
                  fullWidth
                  value={formData.email}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="twitterLink"
                  label="Twiter"
                  fullWidth
                  value={formData.twitterLink}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="instagramLink"
                  label="Instagram"
                  fullWidth
                  value={formData.instagramLink}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="facebookLink"
                  label="Facebook"
                  fullWidth
                  value={formData.facebookLink}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default ContactLog;
