import { useEffect, useState } from "react";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import { POSTNETWORK } from "../../../utils/network";
import ApiUrl from "../../../utils/url";
import { useNavigate } from "react-router-dom";
export default function AddYashilaGallery() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);
  const [formData, setFormData] = useState({ image: "" });

  const [image, setImage] = useState("");

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("image", image);

      const response = await fetch(ApiUrl.GALLERY_POST, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.status) {
        throw new Error("Failed to add team");
      }

      navigate("/yashilagallery");
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <Layout>
      {domLoaded && (
        <Box m="20px" width="50vw">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Yashila Gallery
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex" }}>
            <Box width="100%">
              <Card variant="outlined" sx={{ marginLeft: "250px" }}>
                <CardContent sx={{ border: "1px solid gray" }}>
                  <h5 className="card-title text-center">Add Details</h5>
                  <br />
                  <br />
                  <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          label="Image"
                          id="image"
                          type="file"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          onChange={(e) => setImage(e.target.files[0])}
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button
                          type="submit"
                          variant="contained"
                          color="error"
                          fullWidth
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
