import { Close, Delete, Edit } from "@mui/icons-material";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/global/Layout";
import Header from "../../components/reusable/Header";
import { mockDataYashilaContactUs } from "../../data/mockData";
import { DELETENETWORK, GETNETWORK, PUTNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";

const YashilaContact = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(mockDataYashilaContactUs);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    name: "",
    phone: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  // get All data from database
  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.CONTACT_GETALL);
    console.log("1stresponse", response);
    setData(response.data);
  };

  const handleAdd = () => {
    navigate("/yashilacontact/addyashilacontact");
  };

  const editForm = async (id) => {
    try {
      const response = await GETNETWORK(`${ApiUrl.CONTACT_GETBYID}/${id}`);
      const responseData = response.data;
      console.log("responsedata", responseData);
      setFormData({
        id: responseData.foundContact.id,
        name: responseData.foundContact.name,
        phone: responseData.foundContact.phone,
        email: responseData.foundContact.email,
        message: responseData.foundContact.message,
      });
      setIsModalOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  console.log("setformdata", formData);

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: "",
      name: "",
      phone: "",
      email: "",
      message: "",
    });
  };

  const deleteData = async (id) => {
    await deleteDataByid(id);
  };

  const deleteDataByid = async (id) => {
    await DELETENETWORK(`${ApiUrl.CONTACT_DELETE}/${id}`);
    fetchData();
    console.log("Item with ID:", id, "has been deleted.");
  };

  const handleSubmit = async (e) => {
    console.log("formData", formData);
    e.preventDefault();
    try {
      // debugger
      //  const FinalEditResponse =   await PUTNETWORK(`${ApiUrl.CONTACT_PUT}/${formData.id}`, formData);
      const FinalEditResponse = await PUTNETWORK(
        `http://62.72.31.234:2009/bivabs/yashla/updateContactsById/${formData.id}`,
        formData
      );
      console.log("FinalEditResponse", FinalEditResponse);
      fetchData();
      closeModal();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleChangeModal = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
    console.log("formData1", id, value);
  };

  const theme = useTheme();
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phone,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Message",
      selector: (row) => row.message,
      sortable: true,
    },
    {
      name: "Actions",
      cell: (row) => (
        <div>
          <IconButton onClick={() => editForm(row.id)}>
            <VisibilityRoundedIcon style={{color:'green'}}/>
          </IconButton>
          <IconButton onClick={() => deleteData(row.id)}>
            <Delete style={{color:'red'}}/>
          </IconButton>
        </div>
      ),
      style: { padding: "5px", borderRadius: "5px" },
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Yashila Contact Us"
          subtitle="You can add or modify contact details"
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAdd} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
            <Box sx={{ display: "flex", alignItems: "center" }}></Box>
          </div>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>

          {/* <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              height: "100%",
              width: "350px",
              boxShadow: "0px 0px 10px 2px black",
              borderRadius: "5px",
            }}
          >
            <Box
              style={{
                flex: "1 0 50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <img
                src="/assets/cont.jpeg"
                alt="Your Image"
                style={{ maxWidth: "100%", height: "240px" }}
              />
            </Box>
            <br />
            <Typography
              variant="h5"
              style={{ marginLeft: "2px", color: "black" }}
            >
              This is the contact us page
            </Typography>
          </Box> */}
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              View Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={formData.name}
                  onChange={handleChangeModal}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="phone"
                  label="Phone"
                  variant="outlined"
                  fullWidth
                  value={formData.phone}
                  onChange={handleChangeModal}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="email"
                  label="Email"
                  variant="outlined"
                  fullWidth
                  value={formData.email}
                  onChange={handleChangeModal}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="message"
                  label="Message"
                  variant="outlined"
                  fullWidth
                  value={formData.message}
                  onChange={handleChangeModal}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              {/* <Button
                onClick={handleSubmit}
                type="submit"
                variant="contained"
                color="primary"
              >
                Submit
              </Button> */}
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default YashilaContact;
