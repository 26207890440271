import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Layout from "../../components/global/Layout";
import Header from "../../components/reusable/Header";
import { mockDataBivabBlog } from "../../data/mockData";
import { DELETENETWORK, GETNETWORK, POSTNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";
import Login from "../public/Login";

const Carousel = () => {
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");

  const [editId, setId] = useState();
  const [editedTitle, setEditedTitle] = useState("");
  const [editedSubTitle, setEditedSubTitle] = useState("");
  const [editedDescription, setEditedDescription] = useState("");
  const [editedImage, setEditedImage] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_QNACARAZOL_GETALL);

    setData(response.data);
  };

  const deleteData = async (id) => {
    const responses = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_QNACARAZOL_DELETE}${id}`
    );
    if (responses.status) {
      fetchData();
    }
  };
  const getDateById = async (id) => {
    const responses = await GETNETWORK(
      `${ApiUrl.BIVABDEV_QNACARAZOL_GETBYID}${id}`
    );
    if (responses.status) {
      setId(id);
      setEditedTitle(responses.data.title || "");
      setEditedSubTitle(responses.data.subTitle || "");
      setEditedDescription(responses.data.description || "");
    }
  };
  const handleEdit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("title", editedTitle);
      formDataToSend.append("subTitle", editedSubTitle);
      formDataToSend.append("description", editedDescription);
      formDataToSend.append("image", editedImage);

      const response = await fetch(
        `${ApiUrl.BIVABDEV_QNACARAZOL_UPDATE}${editId}`,
        {
          method: "PUT",
          body: formDataToSend,
        }
      );

      if (!response.ok) {
        throw new Error("Failed to update carousel");
      }

      closeModal();
      setEditedTitle("");
      setEditedSubTitle("");
      setEditedDescription("");

      fetchData();
    } catch (error) {
      console.error("Error updating carousel:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      console.log(image);
      formDataToSend.append("title", title);
      formDataToSend.append("subTitle", subTitle);
      formDataToSend.append("description", description);
      formDataToSend.append("image", image);

      const response = await fetch(ApiUrl.BIVABDEV_QNACARAZOL_CREATE, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.status) {
        throw new Error("Failed to add carousel");
      }
      setTitle("");
      setSubTitle("");
      setDescription("");

      fetchData();
      closeModal();
    } catch (error) {
      console.error("Error adding carousel:", error);
    }
    closeModal();
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    getDateById(id);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Sub Title",
      selector: (row) => row.subTitle,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${ApiUrl.ImageHostURl}${row.imageName}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{ color: "green" }} />
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{ color: "red" }} />
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  if(!localStorage.getItem('email') && !localStorage.getItem('password'))  return <Login/>

  return (
    <Layout>
      <Box m="20px">
        <Header title="Qna Carousel" subtitle="List of carousel details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              onClick={handleAddModalOpen}
              sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              ADD NEW CAROUSEL
            </Button>
          </Box>
        </Box>
        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">
                Add New Carousel
              </Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="title"
                    label="Title"
                    fullWidth
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="subTitle"
                    label="Sub Title"
                    fullWidth
                    value={subTitle}
                    onChange={(e) => setSubTitle(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="description"
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="image"
                    label="Image"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setImage(e.target.files[0])}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" onClick={closeModal} color="error">
                  CANCEL
                </Button>
                <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>

        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="h2">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleEdit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Title"
                  fullWidth
                  value={editedTitle}
                  onChange={(e) => setEditedTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Sub Title"
                  fullWidth
                  value={editedSubTitle}
                  onChange={(e) => setEditedSubTitle(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Description"
                  fullWidth
                  value={editedDescription}
                  onChange={(e) => setEditedDescription(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Image"
                  fullWidth
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEditedImage(e.target.files[0])}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default Carousel;
