import React from 'react'
import { useRef } from 'react';
import { Box, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../constants/theme";
import { mockDataHome } from '../../../data/mockData';
import Carousel from 'react-material-ui-carousel';

import CallIcon from '@mui/icons-material/Call';
import RoomIcon from '@mui/icons-material/Room';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const HomeMobile = () => {
    const contentRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


  return (
    <Box sx={{height:'76.5vh', width:'22.22vw', border:`3px solid ${colors.black[100]}`, borderRadius:'8px', backgroundColor:colors.white[100]}}>
        <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
            <Box ref={contentRef} sx={{height: '800px', position: 'relative' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="2px 11px" marginBottom="10px" sx={{backgroundColor: colors.blue[100] }}>
                        <Typography
                                variant="h4"
                                color={colors.white[100]}
                                fontWeight="bold"
                                sx={{ m: "10px 0 0 0", marginTop:'-5px'}}
                            >
                            Home
                        </Typography>
                        <img
                                alt="profile-user"
                                width="80px"
                                height="50px"
                                src={`/assets/profile-logo.png`}
                                style={{ cursor: "pointer" , filter:'grayscale(1) contrast(0) brightness(1000)'}}
                            />
                    </Box>
                    <Carousel>
                        {mockDataHome.map((item) => (
                            <Box key={item.id} display="flex" flexDirection="column" justifyContent="center">
                                <img
                                    alt="profile-user"
                                    width="326px"
                                    height="200px"
                                    src={`/assets/${item.image}`}
                                    style={{ cursor: "pointer"}}
                                />
                            </Box>
                        ))}
                    </Carousel>
                <Box>
                    <Typography
                    variant="h5"
                    color={colors.grey[100]}
                    fontWeight="bold"
                    sx={{ m: "10px 0 0 0", textAlign:'center', padding:'0px 25px'}}
                    >
                    BUILDING STRUCTURALLY REMARKABLE LANDMARKS
                    </Typography>
                    <Typography
                    variant="h6"
                    color={colors.grey[100]}
                    fontWeight="400"
                    sx={{ m: "10px 0 0 0", padding:'0px 10px'}}
                    >
                    BIVAB® Developers is a well-recognized and a leading real estate brand dealing with both residential and commercial projects. The organization understands and caters to disparate property buying needs of people, especially those looking for apartments for sale in Bhubaneswar, Odisha. The real estate industry is booming and flourishing at its best and at a rapid pace. People who are keen to buy apartments in Bhubaneswar or looking for affordable flats are increasing enormously. But with the assurance of a well justified investment, BIVAB Developers is here to make your real-estate decisions right.!!!
                    </Typography>
                </Box>

                <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center" marginTop="15px" sx={{backgroundColor:colors.black[100], height:'135px'}}>
                    <Typography
                    variant="h5"
                    color={colors.greenAccent[500]}
                    fontWeight="bold"
                    sx={{}}
                    >
                        For More Details
                    </Typography>
                    <Box display="flex" justifyContent="space-around" marginTop="13px">
                        <Box display="flex" alignItems="center" justifyContent="space-around" color={colors.white[100]} width="150px" height="60px"
                            sx={{
                                backgroundColor: colors.darkRed[100]
                            }}>
                            <CallIcon/>
                            <Box>
                                <Typography
                                variant="h6"
                                fontWeight="400"
                                >
                                    CALL US NOW
                                </Typography>
                                <Typography
                                variant="h6"
                                fontWeight="400"
                                >
                                    +91-9856783678
                                </Typography>
                            </Box>
                        </Box>
                        <Box display="flex" alignItems="center" justifyContent="center" color={colors.white[100]} width="150px"
                            sx={{
                                backgroundColor: colors.darkRed[100]
                            }}>
                            <RoomIcon/>
                            <Typography
                            variant="h6"
                            fontWeight="400"
                            >
                                Find us
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="center" alignItems="center" marginTop="25px" height="70px" sx={{backgroundColor: colors.black[100]}}>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{ color: colors.white[100], marginRight:'15px'}}
                        >
                          Also contact us in
                    </Typography>
                    <WhatsAppIcon sx={{color:'white', backgroundColor:'#21d921', width:'36px', height: '38px', borderRadius:'26px'}}/>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default HomeMobile