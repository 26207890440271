import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Modal,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import { mockDataYashilaVideo } from "../../data/mockData";
import DataTable from "react-data-table-component";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { useNavigate } from "react-router-dom";
import { Edit, Delete, Close } from "@mui/icons-material";
import { DELETENETWORK, GETNETWORK, PUTNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";
const YashilaVideo = () => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(mockDataYashilaVideo);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    link: "",
    name: "",
    title: "",
  });
  useEffect(() => {
    fetchData();
  }, []);

  const handleAdd = () => {
    navigate("/yashilavideo/addyashilavideo");
  };

  const editForm = (id) => {
    setSelectedItemId(id);
    getDataByid(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: "",
      link: "",
      name: "",
      title: "",
    });
  };
  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.VIDEO_GETALL);
    console.log(response);
    setData(response.data);
  };
  const deleteData = async (id) => {
    await deleteDataByid(id);
  };
  const deleteDataByid = async (id) => {
    await DELETENETWORK(`${ApiUrl.VIDEO_DELETE}/${id}`);
    fetchData();
    console.log("Item with ID:", id, "has been deleted.");
  };

  const getDataByid = async (id) => {
    const responseData = await GETNETWORK(`${ApiUrl.VIDEO_GETBYID}/${id}`);
    if (responseData.status) {
      const { id, link, name, title } = responseData.data;
      setFormData({ id, link, name, title });

      console.log(responseData);
    }
  };
  const handleSubmit = async () => {
    const { id, link, name, title } = formData;
    console.log("ID:", id);
    console.log("Link:", link);
    console.log("Name:", name);
    console.log("Title:", title);
    const dataUpdateRespone = await PUTNETWORK(
      `${ApiUrl.VIDEO_PUT}/${id}`,
      formData
    );
    if (dataUpdateRespone.status) {
      await fetchData();
      closeModal();
    }
  };
  const handleChangeModal = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };
  const columns = [
    {
      name: "Link",
      selector: (row) => row.link,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Title",
      selector: (row) => row.title,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <div>
          <Button
            onClick={() => editForm(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </div>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Yashila Video"
          subtitle="You can add or modify video details"
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAdd} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
          </div>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>

          {/* <Box style={{ display: 'flex', flexDirection: 'column', marginLeft: '20px', height: '100%', width: '350px', boxShadow: '0px 0px 10px 2px black', borderRadius: '5px' }}>
            <Box style={{ flex: '1 0 50%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '0px' }}>
              <img src="/assets/video.jpeg" alt="Your Image" style={{ maxWidth: '100%', height: '240px' }} />
            </Box>
            <br />
            <Typography variant='h5' style={{ marginLeft: '2px', color: 'black' }}>This is the video gallery page.</Typography>
            <br />

          </Box> */}
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>

          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="link"
                  label="Link"
                  variant="outlined"
                  fullWidth
                  value={formData.link}
                  onChange={handleChangeModal}
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="name"
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={formData.name}
                  onChange={handleChangeModal}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                <TextField
                  id="title"
                  label="Title"
                  variant="outlined"
                  fullWidth
                  value={formData.title}
                  onChange={handleChangeModal}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default YashilaVideo;
