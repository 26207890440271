import { Edit } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import Layout from "../../components/global/Layout";
import Header from "../../components/reusable/Header";
import { mockDataBivabBlog } from "../../data/mockData";
import { GETNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";
import RemoveCircleRoundedIcon from "@mui/icons-material/RemoveCircleRounded";

const ResolveEnquiry = () => {
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
  };

  useEffect(() => {
    fetchData();
  }, []);
  const [data, setData] = useState([]);
  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_ENQ_GETALLRESOLVE);
    console.log(response);
    setData(response.data);
  };
  const handelUpddateStatus = async (id) => {
    const response = await GETNETWORK(`${ApiUrl.BIVABDEV_ENQ_UPDATESTS}/${id}`);
    if (response.status);
    {
      fetchData();
    }
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.fullName,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Phone",
      selector: (row) => row.phoneNo,
      sortable: true,
    },
    {
      name: "Project Name",
      selector: (row) => row.projectName,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => handelUpddateStatus(row.id)}
            style={customStyles.editButton}
          >
            <RemoveCircleRoundedIcon style={{color:'red'}}/>
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Resolve Enquiry"
          subtitle="List of resolve enquiry details"
        />
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
export default ResolveEnquiry;
