import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  styled,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { mockDataBivabBlog } from "../../data/mockData";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close, } from "@mui/icons-material";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { DELETENETWORK, GETNETWORK } from "../../utils/network";
import Login from "../public/Login"

import ApiUrl from "../../utils/url";
const Blog = () => {


  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState("");
  const [formData, setFormData] = useState({
    id: "",
    imageName: "",
    postDate: "",
    heading: "",
    description: "",
    contents: [{ title: "", description: "" }],
  });

  const [imageName, setImageName] = useState("");
  const [postDate, setPostDate] = useState("");
  const [heading, setHeading] = useState("");
  const [description, setDescription] = useState("");
  const [contents, setContents] = useState([{ title: "", description: "" }]);

  const [editId, setEditId] = useState("");
  const [editImageName, setEditImageName] = useState(null);
  const [editPostDate, setEditPostDate] = useState("");
  const [editHeading, setEditHeading] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [editContents, setEditContents] = useState([
    { title: "", description: "" },
  ]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_GETALLBLOG);
    setData(response.data);
  };

  const handelUpdate = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("postDate", editPostDate);
      formDataToSend.append("heading", editHeading);
      formDataToSend.append("description", editDescription);
      formDataToSend.append("image", editImageName);

      editContents.forEach((content, index) => {
        formDataToSend.append(`contents[${index}][title]`, content.title);
        formDataToSend.append(
          `contents[${index}][description]`,
          content.description
        );
      });

      const response = await fetch(`${ApiUrl.BIVABDEV_BLOG_UPDATE}${editId}`, {
        method: "PUT",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to update blog");
      }

      // Clear form fields after successful update
      setEditPostDate("");
      setEditHeading("");
      setEditDescription("");
      setEditImageName(null); // Reset image state if needed

      fetchData();
      closeModal();
    } catch (error) {
      console.error("Error updating blog:", error);
    }
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    getItemByid(id);
    setIsEditModalOpen(true);
  };

  const getItemByid = async (id) => {
    const response = await GETNETWORK(`${ApiUrl.BIVABDEV_BLOG_GETBYID}${id}`);
    if (response.status) {
      setEditPostDate(response.data.postDate);
      setEditHeading(response.data.heading);
      setEditDescription(response.data.description);
      setEditImageName(response.data.imageName);
      setEditContents(response.data.contents);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("postDate", postDate);
      formDataToSend.append("heading", heading);
      formDataToSend.append("description", description);
      formDataToSend.append("image", imageName);

      contents.forEach((content, index) => {
        formDataToSend.append(`contents[${index}][title]`, content.title);
        formDataToSend.append(
          `contents[${index}][description]`,
          content.description
        );
      });

      const response = await fetch(ApiUrl.BIVABDEV_BLOG_CREATE, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to add blog");
      }

      // Clear form fields after successful submission
      setPostDate("");
      setHeading("");
      setDescription("");
      setImageName(null); // Reset image state if needed

      fetchData();
      closeModal();
    } catch (error) {
      console.error("Error adding blog:", error);
    }
  };

  const deleteData = async (id) => {
    const responses = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_BLOG_DELETE}${id}`
    );
    if (responses.status) {
      fetchData();
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
  };

  const handleAddContentField = () => {
    setContents([...contents, { id: "", title: "", description: "" }]);
  };

  const handleRemoveContentField = (index) => {
    const updatedFields = [...contents];
    updatedFields.splice(index, 1);
    setContents(updatedFields);
  };
  const handleContentFieldChange = (index, fieldName, value) => {
    const updatedFields = [...contents];
    updatedFields[index][fieldName] = value;
    setContents(updatedFields);
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'long', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${ApiUrl.ImageHostURl}${row.imageName}`}
          alt="Product"
          style={{ width: "50px", height: "50px", objectFit: "contain" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Post Date",
      selector: (row) => formatDate(row.postDate),
      sortable: true,
    },
    {
      name: "heading",
      selector: (row) => row.heading,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          {/* <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <Edit />
          </Button> */}
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{ color: 'red' }} />
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  const addDynamicField = () => {
    setFormData((prevFormData) => {
      const newContents = [
        ...prevFormData.contents,
        { title: "", description: "" },
      ];
      return { ...prevFormData, contents: newContents };
    });
  };

  const removeDynamicField = (index) => {
    const updatedContents = [...formData.contents];
    updatedContents.splice(index, 1);
    setFormData({
      ...formData,
      contents: updatedContents,
    });
  };

  const handleDynamicFieldChange = (index, key, value) => {
    if (index >= 0 && index < contents.length) {
      const updatedContents = [...contents];
      updatedContents[index][key] = value;
      setFormData({
        ...formData,
        contents: updatedContents,
      });
    }
  };



  if (!localStorage.getItem('email') && !localStorage.getItem('password')) return <Login />

  return (
    <Layout>
      <Box m="20px">
        <Header title="BLOG" subtitle="List of blog details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAddModalOpen} sx={{
              backgroundColor: "black",
              color: "white",
              "&:hover": {
                backgroundColor: "black",
                color: "white",
              },
            }}>
              ADD NEW BLOG
            </Button>
          </Box>
        </Box>
        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 700,
              maxHeight: "80vh",
              overflowY: "auto",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">
                Add New Blog
              </Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="postDate"
                    label="yyyy-mm-dd"
                    fullWidth
                    value={postDate}
                    onChange={(e) => setPostDate(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="heading"
                    label="Heading"
                    fullWidth
                    value={heading}
                    onChange={(e) => setHeading(e.target.value)}
                  />
                </Grid>

                {contents.map((content, index) => (
                  <Grid item xs={12} key={index}>
                    {/* Input fields for dynamic content */}
                    <TextField
                      label="Title"
                      value={content.title}
                      onChange={(e) =>
                        handleContentFieldChange(index, "title", e.target.value)
                      }
                      fullWidth
                      sx={{ marginBottom: "15px" }}
                    />
                    <TextField
                      label="Description"
                      value={content.description}
                      onChange={(e) =>
                        handleContentFieldChange(
                          index,
                          "description",
                          e.target.value
                        )
                      }
                      multiline
                      fullWidth
                    />
                    {/* Button to remove the dynamic content field */}
                    <Button onClick={() => handleRemoveContentField(index)} sx={{
                      backgroundColor: "#1c7e90",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#1c7e90",
                        color: "white",
                      },
                    }}>
                      Remove
                    </Button>
                  </Grid>
                ))}
                {/* Button to add a new dynamic content field */}
                <Grid item xs={12}>
                  <Button onClick={handleAddContentField} sx={{
                    backgroundColor: "#1c7e90",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#1c7e90",
                      color: "white",
                    },
                  }}>
                    Add Content Field
                  </Button>
                </Grid>

                {/* {Array.isArray(formData.contents) &&
                  formData.contents.map((content, index) => (
                    <React.Fragment key={index}>
                      <Grid item xs={12}>
                        <TextField
                          id="title"
                          label="Title"
                          fullWidth
                          value={content.title}
                          onChange={(e) =>
                            handleDynamicFieldChange(
                              index,
                              "title",
                              e.target.value
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div style={{ width: "100%" }}>
                          {" "}
                          <CKEditor
                            editor={ClassicEditor}
                            data={content.description}
                            onChange={(event, editor) =>
                              handleDynamicFieldChange(
                                index,
                                "description",
                                editor.getData()
                              )
                            }
                          />
                        </div>
                      </Grid>
                      <Grid item xs={12}>
                        <Button
                          variant="outlined"
                          onClick={() => removeDynamicField(index)}
                        >
                          Remove
                        </Button>
                      </Grid>
                    </React.Fragment>
                  ))}
                <Grid item xs={12}>
                  <Button variant="outlined" onClick={addDynamicField}>
                    Add Contents
                  </Button>
                </Grid> */}

                <Grid item xs={6}>
                  <TextField
                    id="imageName"
                    label="Image"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setImageName(e.target.files[0])}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="description"
                    label="Description"
                    fullWidth
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" onClick={closeModal} color="error">
                  CANCEL
                </Button>
                <Button type="submit" variant="contained" sx={{
                  backgroundColor: "black",
                  color: "white",
                  "&:hover": {
                    backgroundColor: "black",
                    color: "white",
                  },
                }}>
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            height: "60vh",
            overflow: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handelUpdate}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  id="postDate"
                  label="Post Date"
                  fullWidth
                  value={editPostDate}
                  onChange={(e) => setEditPostDate(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="heading"
                  label="Heading"
                  fullWidth
                  value={editHeading}
                  onChange={(e) => setEditHeading(e.target.value)}
                />
              </Grid>

              {/* Use editContents instead of formData.contents */}
              {Array.isArray(editContents) &&
                editContents.map((content, index) => (
                  <React.Fragment key={index}>
                    <Grid item xs={12}>
                      <TextField
                        id={`title-${index}`}
                        label="Title"
                        fullWidth
                        value={content.title}
                        onChange={(e) =>
                          handleDynamicFieldChange(
                            index,
                            "title",
                            e.target.value
                          )
                        }
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <div style={{ width: "100%" }}>
                        <CKEditor
                          editor={ClassicEditor}
                          data={content.description}
                          onChange={(event, editor) =>
                            handleDynamicFieldChange(
                              index,
                              "description",
                              editor.getData()
                            )
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <Button
                        variant="outlined"
                        onClick={() => removeDynamicField(index)}
                      >
                        Remove
                      </Button>
                    </Grid>
                  </React.Fragment>
                ))}
              <Grid item xs={12}>
                <Button variant="outlined" onClick={addDynamicField}>
                  Add Contents
                </Button>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="imageName"
                  label="Image"
                  fullWidth
                  type="file"
                  InputLabelProps={{ shrink: true }}
                  onChange={(e) => setEditImageName(e.target.files[0])}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="description"
                  label="Description"
                  fullWidth
                  value={editDescription}
                  onChange={(e) => setEditDescription(e.target.value)}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                CANCEL
              </Button>
              <Button type="submit" variant="contained" color="primary">
                SUBMIT
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default Blog;
