import { useEffect, useState } from "react";

import { Box, Button, IconButton, Typography, useTheme } from "@mui/material";

import Header from "../../components/reusable/Header";
import { tokens } from "../../constants/theme";

import Layout from "../../components/global/Layout";
import DashboardComponent from "../../components/DashboardComponent";
import YashilaDashboardComponent from "../../components/YashilaDashboardComponent";
import Login from "../public/Login";

export default function Dashboard() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  if(!localStorage.getItem('email') && !localStorage.getItem('password'))  return <Login/>

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px">
          {/* HEADER */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Header title="DASHBOARD" subtitle="Welcome to your dashboard" />
          </Box>

          {/* Main Content */}
          <Box>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: "#7e7e7e",
                marginBottom: 1,
                fontSize: "25px",
                fontWeight: "700",
                marginBottom: "50px",
              }}
            >
              Bivab Developers
            </Typography>
            <Box>
              <DashboardComponent />
            </Box>
            <Typography
              variant="h5"
              component="div"
              sx={{
                color: "#7e7e7e",
                marginBottom: 1,
                fontSize: "25px",
                fontWeight: "700",
                marginTop: "50px",
                marginBottom: "50px",
              }}
            >
              Bivab Yashila
            </Typography>

            <Box>
              <YashilaDashboardComponent />
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
