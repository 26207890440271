import { useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  useTheme,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import EnquiryMobile from "../../../components/reusable/mobile/EnquiryMobile";

export default function AddEmployee() {
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            EMPLOYEE
          </Typography>
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Card
              variant="outlined"
              sx={{ paddingLeft: "20px", paddingRight: "20px" }}
            >
              <CardContent>
                <h6 class="card-title text-center">Add Details</h6>
                <br />
                <br />
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        id="ID"
                        label="ID"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="age"
                        label="Age"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        id="access"
                        label="Access"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        label="Profile"
                        id="inputGroupFile01"
                        type="file"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} my={4}>
                      <Button variant="contained" color="error" fullWidth>
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </CardContent>
            </Card>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
