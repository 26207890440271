import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Modal,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../constants/theme";
import { mockDataYashilaProperties } from "../../data/mockData";
import { useTheme } from "@mui/material";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const YashilaProperties = () => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(mockDataYashilaProperties);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [formData, setFormData] = useState({
    name: "",
    location: "",
    price: "",
    price_per_sqft: "",
    size: "",
    rooms: "",
    description: "",
    image1: "",
    image2: "",
    image3: "",
  });
  const handleAdd = () => {
    navigate("/yashilaproperties/addyashilaproperties");
  };

  const editForm = (id) => {
    setSelectedItemId(id);
    setIsModalOpen(true);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: "",
      name: "",
      email: "",
      description: "",
    });
  };

  const deleteData = (id) => {
    const updatedData = filteredData.filter((item) => item.id !== id);
    setFilteredData(updatedData);
    console.log("Item with ID:", id, "has been deleted.");
  };

  const handleSubmit = () => {
    console.log("Form submitted with data:", formData);
    closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {};
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Price Per Sqft",
      selector: (row) => row.price_per_sqft,
      sortable: true,
    },
    {
      name: "Size",
      selector: (row) => row.size,
      sortable: true,
    },
    {
      name: "Rooms",
      selector: (row) => row.rooms,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Image 1",
      selector: (row) => row.image1,
      sortable: true,
    },
    {
      name: "Image 2",
      selector: (row) => row.image2,
      sortable: true,
    },
    {
      name: "Image 3",
      selector: (row) => row.image3,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => editForm(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Yashila Properties"
          subtitle="You can add or modify property details"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAdd} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Box width="49vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              height: "100%",
              width: "350px",
              boxShadow: "0px 0px 10px 2px black",
              borderRadius: "5px",
            }}
          >
            <Box
              sx={{
                flex: "1 0 50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <img
                src="/assets/prop.jpeg"
                alt="Your Image"
                style={{ maxWidth: "100%", height: "240px" }}
              />
            </Box>
            <br />
            <Typography
              variant="h5"
              style={{ marginLeft: "2px", color: "black" }}
            >
              This is the property page.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="center"
            mb={2}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Grid>
          <form onSubmit={(e) => handleSubmit(e, formData)}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="ID"
                  variant="outlined"
                  name="id"
                  type="text"
                  value={formData.id}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  name="name"
                  type="text"
                  value={formData.name}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Location"
                  variant="outlined"
                  name="location"
                  type="text"
                  value={formData.location}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Price"
                  variant="outlined"
                  name="price"
                  type="text"
                  value={formData.price}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Price Per Sqft"
                  variant="outlined"
                  name="price_per_sqft"
                  type="text"
                  value={formData.price_per_sqft}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Size"
                  variant="outlined"
                  name="size"
                  type="text"
                  value={formData.size}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Rooms"
                  variant="outlined"
                  name="rooms"
                  type="text"
                  value={formData.rooms}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Description"
                  variant="outlined"
                  name="description"
                  type="text"
                  value={formData.description}
                  onChange={handleChange}
                  fullWidth
                  margin="normal"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Img1"
                  id="inputGroupFile01"
                  type="file"
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Img2"
                  id="inputGroupFile01"
                  type="file"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label="Img3"
                  id="inputGroupFile01"
                  type="file"
                  fullWidth
                />
              </Grid>
            </Grid>
            {formData.image && (
              <Typography variant="body1">{formData.image.name}</Typography>
            )}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Submit
              </Button>
            </Box>
          </form>
        </Grid>
      </Modal>
    </Layout>
  );
};

export default YashilaProperties;
