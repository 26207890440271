import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  styled,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { mockDataBivabBlog } from "../../data/mockData";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
import { GETNETWORK, DELETENETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";

const Projects = () => {
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);

  const [formData, setFormData] = useState({
    id: "",
    projectType: "",
    status: "",
    name: "",
    location: "",
    overview: "",
    BHK: "",
    unit: "",
    floors: "",
    approval: "",
    powerBackup: "",
    parking: "",
    elevator: "",
    security: "",
    intercomAndWirelessConnectivity: "",
    projectDocument: "",
    images: [],
  });

  const [projectType, setProjectType] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState("");
  const [location, setLocation] = useState("");
  const [overview, setOverview] = useState("");
  const [bhk, setBhk] = useState("");
  const [unit, setUnit] = useState("");
  const [floors, setFloors] = useState("");
  const [approval, setApproval] = useState("");
  const [powerBackup, setPowerBackup] = useState("");
  const [parking, setParking] = useState("");
  const [elevator, setElevator] = useState("");
  const [security, setSecurity] = useState("");
  const [intercomAndWirelessConnectivity, setIntercomAndWirelessConnectivity] =
    useState("");
  const [projectDocument, setProjectDocument] = useState("");
  const [image, setImage] = useState([]);

  const [editId, setEditId] = useState("");
  const [editProjectType, setEditProjectType] = useState("");
  const [editStatus, setEditStatus] = useState("");
  const [editName, setEditName] = useState("");
  const [editLocation, setEditLocation] = useState("");
  const [editOverview, setEditOverview] = useState("");
  const [editBhk, setEditBhk] = useState("");
  const [editUnit, setEditUnit] = useState("");
  const [editFloors, setEditFloors] = useState("");
  const [editApproval, setEditApproval] = useState("");
  const [editPowerBackup, setEditPowerBackup] = useState("");
  const [editParking, setEditParking] = useState("");
  const [editElevator, setEditElevator] = useState("");
  const [editSecurity, setEditSecurity] = useState("");
  const [
    editIntercomAndWirelessConnectivity,
    setEditIntercomAndWirelessConnectivity,
  ] = useState("");
  const [editProjectDocument, setEditProjectDocument] = useState("");
  const [editImage, setEditImage] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.BIVABDEV_PROJECT_GETALL);
    console.log(response);
    setData(response.data);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("projectType", projectType);
      formDataToSend.append("status", status);
      formDataToSend.append("name", name);
      formDataToSend.append("location", location);
      formDataToSend.append("overview", overview);
      formDataToSend.append("BHK", bhk);
      formDataToSend.append("unit", unit);
      formDataToSend.append("floors", floors);
      formDataToSend.append("approval", approval);
      formDataToSend.append("powerBackup", powerBackup);
      formDataToSend.append("parking", parking);
      formDataToSend.append("elevator", elevator);
      formDataToSend.append("security", security);
      formDataToSend.append(
        "intercomAndWirelessConnectivity",
        intercomAndWirelessConnectivity
      );
      formDataToSend.append("projectDocument", projectDocument);

      for (let i = 0; i < image.length; i++) {
        formDataToSend.append("images", image[i]);
      }

      const response = await fetch(ApiUrl.BIVABDEV_PROJECT_CREATE, {
        method: "POST",
        body: formDataToSend,
      });

      if (!response.ok) {
        throw new Error("Failed to add blog");
      }

      setProjectType("");
      setStatus("");
      setName("");
      setLocation("");
      setOverview("");
      setBhk("");
      setUnit("");
      setFloors("");
      setApproval("");
      setPowerBackup("");
      setParking("");
      setElevator("");
      setSecurity("");
      setIntercomAndWirelessConnectivity("");
      setProjectDocument(null);
      setImage(null);

      fetchData();
      closeModal();
    } catch (error) {
      console.error("Error adding blog:", error);
    }
  };

  const deleteData = async (id) => {
    const responses = await DELETENETWORK(
      `${ApiUrl.BIVABDEV_PROJECT_DELETE}${id}`
    );
    if (responses.status) {
      fetchData();
    }
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setFormData({
      id: "",
      projectType: "",
      status: "",
      name: "",
      location: "",
      overview: "",
      BHK: "",
      unit: "",
      floors: "",
      approval: "",
      powerBackup: "",
      parking: "",
      elevator: "",
      security: "",
      intercomAndWirelessConnectivity: "",
      projectDocument: "",
      images: [],
    });
  };

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Project Type",
      selector: (row) => row.projectType,
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row.status,
      sortable: true,
    },
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Overview",
      selector: (row) => row.overview,
      sortable: true,
    },
    {
      name: "BHK",
      selector: (row) => row.BHK,
      sortable: true,
    },
    {
      name: "Unit",
      selector: (row) => row.unit,
      sortable: true,
    },
    {
      name: "Floors",
      selector: (row) => row.floors,
      sortable: true,
    },
    {
      name: "Approval",
      selector: (row) => row.approval,
      sortable: true,
    },
    {
      name: "Power Backup",
      selector: (row) => row.powerBackup,
      sortable: true,
    },
    {
      name: "Parking",
      selector: (row) => row.parking,
      sortable: true,
    },
    {
      name: "Elevator",
      selector: (row) => row.elevator,
      sortable: true,
    },
    {
      name: "Security",
      selector: (row) => row.security,
      sortable: true,
    },
    {
      name: "Connectivity",
      selector: (row) => row.intercomAndWirelessConnectivity,
      sortable: true,
    },
    {
      name: "Document",
      selector: (row) => row.projectDocument,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => {
        // Filter out images with PDF extension
        const imageFiles = row.images.filter(image => {
          const extension = image.imageName.split('.').pop().toLowerCase();
          return ['jpg', 'jpeg', 'png'].includes(extension);
        });
        
        // Check if there are any valid image files
        if (imageFiles.length > 0) {
          // Select a random image from the filtered images
          const randomImage = imageFiles[Math.floor(Math.random() * imageFiles.length)];
          return (
            <img
              src={`${ApiUrl.ImageHostURl}${randomImage.imageName}`}
              alt="Product"
              style={{ width: "50px", height: "50px", objectFit: "contain" }}
            />
          );
        } else {
          // If no valid image files found, you can render a placeholder or handle it accordingly
          return (
            <span>No image available</span>
          );
        }
      },
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          {/* <Button onClick={() => handleEditModalOpen(row.id)} style={customStyles.editButton}>
            <Edit />
          </Button> */}
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{ color: "red" }} />
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  const addDynamicField = () => {
    setFormData({
      ...formData,
      images: formData.images.concat({ projectId: "", imageName: "" }),
    });
  };
  const removeDynamicField = (index) => {
    const updatedImages = [...formData.images];
    updatedImages.splice(index, 1);
    setFormData({
      ...formData,
      images: updatedImages,
    });
  };
  const handleDynamicFieldChange = (index, key, value) => {
    const updatedImages = [...formData.images];
    updatedImages[index][key] = value;
    setFormData({
      ...formData,
      images: updatedImages,
    });
  };
  const handleImageUpload = (index, file) => {
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64Image = reader.result;
        const updatedImages = [...formData.images];
        updatedImages[index] = {
          ...updatedImages[index],
          imageFile: file,
          base64Image: base64Image,
        };
        setFormData({ ...formData, images: updatedImages });
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Layout>
      <Box m="20px">
        <Header title="Projects" subtitle="List of project details" />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              onClick={handleAddModalOpen}
              sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              ADD NEW PROJECTS
            </Button>
          </Box>
        </Box>

        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              maxHeight: "80vh",
              overflowY: "auto",
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h5">Add New Project</Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    id="projectType"
                    label="Project Type"
                    fullWidth
                    value={projectType}
                    onChange={(e) => setProjectType(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="status"
                    label="Status"
                    fullWidth
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="name"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="location"
                    label="Location"
                    fullWidth
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="overview"
                    label="Overview"
                    fullWidth
                    value={overview}
                    onChange={(e) => setOverview(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="bhk"
                    label="BHK"
                    fullWidth
                    value={bhk}
                    onChange={(e) => setBhk(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="unit"
                    label="Unit"
                    fullWidth
                    value={unit}
                    onChange={(e) => setUnit(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="floors"
                    label="Floors"
                    fullWidth
                    value={floors}
                    onChange={(e) => setFloors(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="approval"
                    label="Approval"
                    fullWidth
                    value={approval}
                    onChange={(e) => setApproval(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="powerBackup"
                    label="Power Backup"
                    fullWidth
                    value={powerBackup}
                    onChange={(e) => setPowerBackup(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="parking"
                    label="Parking"
                    fullWidth
                    value={parking}
                    onChange={(e) => setParking(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="elevator"
                    label="Elevator"
                    fullWidth
                    value={elevator}
                    onChange={(e) => setElevator(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="security"
                    label="Security"
                    fullWidth
                    value={security}
                    onChange={(e) => setSecurity(e.target.value)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    id="intercomAndWirelessConnectivity"
                    label="Connectivity"
                    fullWidth
                    value={intercomAndWirelessConnectivity}
                    onChange={(e) =>
                      setIntercomAndWirelessConnectivity(e.target.value)
                    }
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="fileName"
                    label="Project Document"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setProjectDocument(e.target.files[0])}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    id="fileName"
                    label="image"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                    inputProps={{ multiple: true }}
                    onChange={(e) => setImage(e.target.files)}
                  />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" onClick={closeModal} color="error">
                  CANCEL
                </Button>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "black",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "black",
                      color: "white",
                    },
                  }}
                >
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>

        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            maxHeight: "80vh",
            overflowY: "auto",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="h2">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField id="projectType" label="Project Type" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="status" label="Status" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="name" label="Name" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="location" label="Location" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="overview" label="Overview" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="BHK" label="BHK" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="unit" label="Unit" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="floors" label="Floors" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="approval" label="Approval" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="powerBackup" label="Power Backup" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="parking" label="Parking" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="elevator" label="Elevator" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField id="security" label="Security" fullWidth />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  id="intercomAndWirelessConnectivity"
                  label="Connectivity"
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <TextField id="projectDocument" label="Document" fullWidth />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default Projects;
