//mockdata

export const mockDataHome = [
  {
    id: '1',
    serialNumber:'1',
    imageName: "project1.png",
    title: "BUILDING STRUCTURALLY REMARKABLE LANDMARKS",
  },
  {
    id: 2,
    image: "project2.webp",
    title: "BUILDING STRUCTURALLY",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 3,
    image: "project3.jpg",
    title: "REMARKABLE LANDMARKS",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 4,
    image: "project4.webp",
    title: "BUILDING STRUCTURALLY REMARKABLE LANDMARKS",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 5,
    image: "project5.webp",
    title: "BUILDING STRUCTURALLY REMARKABLE LANDMARKS",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
];


export const mockDataAbout = [
  {
    id: 1,
    image: "aboutProfile1.jpeg",
    name: "FOUNDER BIVAB",
    title: "Er. Binay Krishna Dash",
    description: "BIVAB® Developers is a well-recognized and a leading real estate brand dealing with both residential and commercial projects. The organization understands and caters to disparate property buying needs of people, especially those looking for apartments for sale in Bhubaneswar, Odisha."
  },
  {
    id: 2,
    image: "aboutProfile2.jpeg",
    name: "DIRECTOR",
    title: "Mrs. Eva Pattnaik",
    description: "BIVAB® Developers is a well-recognized and a leading real estate brand dealing with both residential and commercial projects. The organization understands and caters to disparate property buying needs of people, especially those looking for apartments for sale in Bhubaneswar, Odisha."
  },
];


export const mockDataProject = [
  {
    id: "1",
    profile: "project1.png",
    image: "building",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Sold Out",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 2,
    profile: "project2.webp",
    image: "building",
    type: "Residential",
    name: "Bivab Bhabani Residential",
    location: "Patia, Bhubaneswar",
    status: "Available",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 3,
    profile: "project3.jpg",
    type: "Residential",
    name: "Bivab Satyabadi Residential",
    location: "Chandrasekharpur, Bhubaneswar",
    status: "Sold Out",
    unit: "46",
    floors: "G+4",
    bhk: "3BHK",
  },
  {
    id: 4,
    profile: "project4.webp",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Sold Out",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 5,
    profile: "project5.webp",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Sold Out",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 6,
    profile: "project6.webp",
    type: "Residential",
    name: "Bivab Gulmohar Residential",
    location: "Nayapalli, Bhubaneswar",
    status: "Available",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 7,
    profile: "project5.webp",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Available",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 8,
    profile: "project4.webp",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Available",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 9,
    profile: "project4.webp",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Available",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 10,
    profile: "project6.webp",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Available",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
  {
    id: 11,
    profile: "project2.webp",
    type: "Commercial",
    name: "Bivab Gulmohar Commercial",
    location: "Bhimtangi, Bhubaneswar",
    status: "Available",
    unit: "36",
    floors: "G+4",
    bhk: "2BHK",
  },
];


export const mockDataEnquiry = [
  {
    id: 1,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 2,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 3,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 4,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 5,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 6,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 7,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 8,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
  {
    id: 9,
    question: "What is the primary purpose of a foundation in a building?",
    option1: "To provide structural support",
    option2: "To store construction materials",
    option3: "To enhance the building's appearance",
    option4: "To generate electricity",
  },
];


export const mockDataEmployee = [
  {
    id: "1",
    profile: "emp1.webp",
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    age: 35,
    phone: "(665)121-5454",
    access: "admin",
  },
  {
    id: 2,
    profile: "emp2.webp",
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    age: 42,
    phone: "(421)314-2288",
    access: "manager",
  },
  {
    id: 3,
    profile: "emp3.jpg",
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    age: 45,
    phone: "(422)982-6739",
    access: "user",
  },
  {
    id: 4,
    profile: "emp4.jpg",
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    age: 16,
    phone: "(921)425-6742",
    access: "admin",
  },
  {
    id: 5,
    profile: "emp5.jpg",
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    age: 31,
    phone: "(421)445-1189",
    access: "user",
  },
  {
    id: 6,
    profile: "emp6.jpg",
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    age: 150,
    phone: "(232)545-6483",
    access: "manager",
  },
  {
    id: 7,
    profile: "emp3.jpg",
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    age: 44,
    phone: "(543)124-0123",
    access: "user",
  },
  {
    id: 8,
    profile: "emp1.webp",
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    age: 36,
    phone: "(222)444-5555",
    access: "user",
  },
  {
    id: 9,
    profile: "emp4.jpg",
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    age: 65,
    phone: "(444)555-6239",
    access: "admin",
  },
];


export const mockDataUser = [
  {
    id: 1,
    profile: "emp1.webp",
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    phone: "(665)121-5454",
    address: "0912 Won Street, Alabama, SY 10001",
  },
  {
    id: 2,
    profile: "emp2.webp",
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    phone: "(421)314-2288",
    address: "0912 Won Street, Alabama, SY 10001",
  },
  {
    id: 3,
    profile: "emp3.jpg",
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    phone: "(422)982-6739",
    address: "0912 Won Street, Alabama, SY 10001",
  },
  {
    id: 4,
    profile: "emp4.jpg",
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    phone: "(921)425-6742",
    address: "0912 Won Street, Alabama, SY 10001",
  },
  {
    id: 5,
    profile: "emp5.jpg",
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    phone: "(421)445-1189",
    address: "0912 Won Street, Alabama, SY 10001",
  },
  {
    id: 6,
    profile: "emp6.jpg",
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    phone: "(232)545-6483",
    address: "0912 Won Street, Alabama, SY 10001",
  },
  {
    id: 7,
    profile: "emp3.jpg",
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    phone: "(543)124-0123",
    address: "0912 Won Street, Alabama, SY 10001",
  },
  {
    id: 8,
    profile: "emp1.webp",
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    phone: "(222)444-5555",
    address: "0912 Won Street, Alabama, SY 10001",
  },
];


export const mockDataUserAnswer = [
  {
    id: "1",
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
  {
    id: 9,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    answer1: "To store construction materials",
    answer2: "To store construction materials",
    answer3: "To store construction materials",
    answer4: "To store construction materials",
    answer5: "To store construction materials",
    answer6: "To store construction materials",
    answer7: "To store construction materials",
    answer8: "To store construction materials",
    answer9: "To store construction materials",
  },
];


export const mockDataTestimonial = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    description: "BIVAB Developers is a well-recognized and a leading real estate brand dealing with bodth residential and commercial projects.",
  },
];


export const mockDataContacts = [
  {
    address: 'bbsr',
    phoneNumber: '1234567899',
    email: 'abc@gmail.com',
    twitterLink: "https://bivabyashila.com/",
    instagramLink: "https://bivabyashila.com/",
    facebookLink:'https://bivabyashila.com/'
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    phone: "(421)314-2288",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    phone: "(422)982-6739",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    phone: "(921)425-6742",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    phone: "(421)445-1189",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    phone: "(232)545-6483",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    phone: "(543)124-0123",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    phone: "(222)444-5555",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    phone: "(444)555-6239",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
  {
    id: 10,
    name: "Enteri Redack",
    email: "enteriredack@gmail.com",
    phone: "(222)444-5555",
    message: "0912 Won Street, Alabama, SY 10001",
    question: "New York",
  },
];


export const mockDataCareer = [
  {
    id: 1,
    name: "Jon Snow",
    email: "jonsnow@gmail.com",
    phone: "(665)121-5454",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 2,
    name: "Cersei Lannister",
    email: "cerseilannister@gmail.com",
    phone: "(421)314-2288",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 3,
    name: "Jaime Lannister",
    email: "jaimelannister@gmail.com",
    phone: "(422)982-6739",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 4,
    name: "Anya Stark",
    email: "anyastark@gmail.com",
    phone: "(921)425-6742",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 5,
    name: "Daenerys Targaryen",
    email: "daenerystargaryen@gmail.com",
    phone: "(421)445-1189",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 6,
    name: "Ever Melisandre",
    email: "evermelisandre@gmail.com",
    phone: "(232)545-6483",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 7,
    name: "Ferrara Clifford",
    email: "ferraraclifford@gmail.com",
    phone: "(543)124-0123",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 8,
    name: "Rossini Frances",
    email: "rossinifrances@gmail.com",
    phone: "(222)444-5555",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
  {
    id: 9,
    name: "Harvey Roxie",
    email: "harveyroxie@gmail.com",
    phone: "(444)555-6239",
    location: "0912 Won Street, Alabama, SY 10001",
    gender: "male",
    education: "graduation",
    position: "Junior Developer",
    experience: "2 year",
    resume: "sample.pdf"
  },
];


export const mockTransactions = [
  {
    txId: "01e4dsa",
    user: "johndoe",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "51034szv",
    user: "goodmanave",
    date: "2022-11-05",
    cost: "200.95",
  },
  {
    txId: "0a123sb",
    user: "stevebower",
    date: "2022-11-02",
    cost: "13.55",
  },
  {
    txId: "01e4dsa",
    user: "aberdohnny",
    date: "2021-09-01",
    cost: "43.95",
  },
  {
    txId: "120s51a",
    user: "wootzifer",
    date: "2019-04-15",
    cost: "24.20",
  },
  {
    txId: "0315dsaa",
    user: "jackdower",
    date: "2022-04-01",
    cost: "133.45",
  },
];

export const mockDataYashilaProperties = [
  {
    id: '1',
    name: "Bivab Yashila(Mogra)",
    location: "Puri, Odisha, India",
    price: "₹1.22Cr",
    price_per_sqft: "₹7750/sqft",
    size: "1580sqft",
    rooms: "2 BHK",
    description: "The Mogra, a flower held in high esteem by revered deities such as Lord Vishnu and Goddess Laxmi, is an enchanting bloom that serves as the..more",
    image1: "/assets/y1.jpg",
    image2: "/assets/y2.jpg",
    image3: "/assets/y4.jpg"
  },
  {
    id: '2',
    name: "Bivab Yashila(Orchid)",
    location: "Puri, Odisha, India",
    price: "₹1.12Cr",
    price_per_sqft: "₹7750/sqft",
    size: "1450sqft",
    rooms: "2 BHK",
    description: "Orchids' unique beauty and scarcity have rendered them as a symbol of opulence, status, and prestige. In certain spiritual practices, orchids are...more",
    image1: "/assets/y1.jpg",
    image2: "/assets/y2.jpg",
    image3: "/assets/y4.jpg"
  },
  {
    id: '3',
    name: "Bivab Yashila(Neelkamal)",
    location: "Puri, Odisha, India",
    price: "₹ 79L",
    price_per_sqft: "₹7750/sqft",
    size: "1020sqft",
    rooms: "1 BHK",
    description: "The Neelkamal units, a crown jewel of our realm. These enchanted 1bhk units, blessed with the allure of the sea's embrace, offer a vista...more",
    image1: "/assets/y1.jpg",
    image2: "/assets/y2.jpg",
    image3: "/assets/y4.jpg"
  },
  {
    id: '4',
    name: "Bivab Yashila(Parijat)",
    location: "Puri, Odisha, India",
    price: "₹ 62L",
    price_per_sqft: "₹7750/sqft",
    size: "805sqft",
    rooms: "1 BHK",
    description: "As legend whispers, the goddess of prosperity, T Lakshmi, delights in the fragrant petals of the parijat blooms. This enchanting tree, steeped...more",
    image1: "/assets/y1.jpg",
    image2: "/assets/y2.jpg",
    image3: "/assets/y4.jpg"
  },
];
export const mockDataYashilaGetintouch = [
  {
    id: '1',
    name: "abc",
    phone: "9937129034",
    email: "sales@bivabyashila.com",
    message: "hii",
  }
];
export const mockDataYashilaGetintouchinfo = [
  {
    id: "1",
    location: "4th Floor,Bivab Gulmohar commercial Nayapalli,Bhubaneswar-751012,Odisha",
    email: "sales@bivabyashila.com",
    primary_number: "+919437345524",
    secondary_number: "7381863666",
  }
];
export const mockDataYashilaAboutBivab = [
  {
    id: "1",
    image: "b.jpeg",
    title1: "ABOUT BIVAB",
    title2: "Bivab Developers",
    description1: "Welcome to BIVAB® Yashila, a distinctive residential enclave in the heart of Puri, Odisha. Boasting over 300 meticulously crafted apartments, BIVAB Yashila is set to redefine modern living in this serene coastal town.",
    description2: "Discover a harmonious living experience with thoughtfully designed spaces, modern amenities, and a scenic backdrop that echoes the spirit of Puri. Each apartment at BIVAB Yashila is a testament to our commitment to quality and excellence.",
  },
];
export const mockDataYashilaAboutOwners = [
  {
    id: '2',
    image: 'binay.jpeg',
    name: 'Er. Binay Krishna Das',
    designation: 'FOUNDER',
    about: 'FOUNDER OF BIVAB',
  },
];
export const mockDataYashilaAboutBivabCommercial = [
  {
    id: '1',
    title: 'Bivab Yashila Commercial',
    description: 'BIVAB Yashila Commercial redefines business in Puri with a prime location designed for success. Our commercial spaces offer a strategic address, merging coastal allure with professional efficacy. Elevate your enterprise in an environment that fosters innovation and growth. BIVAB Yashila Commercial is not just a workspace; it’s a gateway to a thriving business community, where the vibrancy of Puri converges with your entrepreneurial aspirations.',
  },
];
export const mockDataYashilaVideo = [
  {
    id: '1',
    link: 'https://bivabyashila.com/',
    name: 'OUR VIDEO',
    title: 'Bivab Yashila',
  },
];
export const mockDataYashilaMision = [
  {
    id: '1',
    title: 'Mision',
    description: 'BIVAB Yashila envisions being the ultimate destination for seaside luxury living in Jagannathdham. With a focus on architectural brilliance, environmental responsibility, and unparalleled amenities, we strive to create a legacy where dreams of a sophisticated coastal lifestyle become a reality.',
  },
];
export const mockDataYashilaVision = [
  {
    id: '1',
    title: 'Vision',
    description: 'BIVAB Yashila envisions being the ultimate destination for seaside luxury living in Jagannathdham. With a focus on architectural brilliance, environmental responsibility, and unparalleled amenities, we strive to create a legacy where dreams of a sophisticated coastal lifestyle become a reality.',
  },
];
export const mockDataYashilaSuccessCountup = [
  {
    id: '1',
    number: '100',
    title: 'Count Up',
  },
];
export const mockDataYashilaGallery = [
  {
    id: '1',
    image: 'g.jpeg',
  },
];
export const mockDataYashilaContactUs = [
  {
    id: '1',
    name: 'Bivab',
    phone: '7381097302',
    email: 'sales@bivabyashila.com',
    message: 'hii',
  },

];
export const mockDataBivabGetintouch = [
  {
    id: "32b13253-ae8f-4f68-ac1a-8b51bb545b76",
    fullName: "lokij",
    emailId: "faz@example.com",
    phoneNo: "1894568902",
    subject: "hindi",
    message: "yes"
  },
]
export const mockDataBivabOwner = [
  {
    id: '1',
    imageName: 'g.jpeg',
    name: 'bivab',
    designation: 'bivabsss',
  },
]
export const mockDataBivabTeam = [
  {
    id: '1',
    image: 'g.jpeg',
    name: 'bivab',
    designation: 'bivabsss',
  },
]
export const mockDataBivabCarousel = [
  {
    id: '1',
    title: 'gbbb',
    subTitle: 'bivab',
    description: 'bivabsss',
  },
]
export const mockDataBivabBlog = [
  {
    id: '1',
    postDate: 'gbbb',
    imageName: 'g.jpeg',
    heading:'hii',
    contents:'aaaaaaaa',
    description: 'bivabsss',
  },
]
