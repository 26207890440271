import { useEffect, useState } from "react";
import {
  useTheme,
  Box,
  TextField,
  Button,
  Card,
  Grid,
  CardContent,
  Typography,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import { POSTNETWORK } from "../../../utils/network";
import ApiUrl from "../../../utils/url";
import { useNavigate } from "react-router-dom";

export default function AddYashilaContact() {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      name: name,
      phone: phone,
      email: email,
      message: message,
    };
    try {
      const formDataResponse = await POSTNETWORK(ApiUrl.CONTACT_POST, formData);
      if (formDataResponse.status) {
        navigate("/yashilacontact");
      }
      console.log(formData);
    } catch (error) {
      console.error("Error while submitting form:", error);
    }
  };
  return (
    <Layout>
      {domLoaded && (
        <Box m="20px auto" width="90%">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Yashila Contact
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Box width="70%">
              <Card variant="outlined">
                <CardContent style={{ border: "1px solid gray" }}>
                  <Typography variant="h5" align="center" gutterBottom>
                    Add Details
                  </Typography>
                  <form>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextField
                          id="Name"
                          label="name"
                          variant="outlined"
                          fullWidth
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="Phone"
                          label="phone"
                          variant="outlined"
                          fullWidth
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="Email"
                          label="email"
                          variant="outlined"
                          fullWidth
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="Message"
                          label="message"
                          variant="outlined"
                          fullWidth
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button
                          variant="contained"
                          color="error"
                          fullWidth
                          onClick={handleFormSubmit}
                        >
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
