import React, { useState } from "react";
import {
  Container,
  CssBaseline,
  Typography,
  TextField,
  Button,
  Box,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { POSTNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";

const LoginRegistrationPage = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [getEmail, setGetEmail] = useState("");
  const [getPassword, setGetPassword] = useState("");

  const handleSignIn = () => {
    navigate("/dashboard");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await POSTNETWORK(`${ApiUrl.ADMIN_LOGIN}`, {
        email,
        password,
      });
      const adminData = response.data;

      if (adminData) {
        localStorage.setItem("email", email);
        localStorage.setItem("password", password);

        navigate("/dashboard");
        window.location.reload();
      } else {
        alert("Invalid email or password. Please try again.");
      }
    } catch (error) {
      console.error("Error fetching admin:", error);
    }
  };

  const isMobile = useMediaQuery("(max-width:450px)");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        height: "100vh",
        margin: "auto",
        background: "white",
        justifyContent: "center",
        flexDirection: isMobile ? "column" : "row",
      }}
    >
      <Box>
        <Box
          sx={{
            display: "flex",
            margin: "auto",
            flexDirection: isMobile ? "column" : "row",
            backgroundColor: "white",
            alignItems: "center",
          }}
        >
          <Container
            sx={{
              width: "100%",
              backgroundColor: "#f4faff",
              borderTopLeftRadius: isMobile ? "0px" : "34px",
              borderBottomLeftRadius: isMobile ? "0px" : "34px",
              boxShadow: isMobile ? "none" : "5px 5px 10px #767474",
              height: isMobile ? "67vh" : "72vh",
            }}
          >
            <CssBaseline />
            <Box
              elevation={3}
              sx={{
                padding: 3,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                paddingTop: "61px",
              }}
            >
              <Typography
                component="h1"
                variant="h5"
                sx={{ color: "#be1719", fontWeight: "700" }}
              >
                Sign in to Account
              </Typography>

              <Typography variant="p" sx={{ color: "gray", fontWeight: "400" }}>
                use your email id
              </Typography>

              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label="Email Address or Login ID"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{
                    background: "lightblack",
                    "& input": { color: "black" },
                  }}
                />

                <TextField
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  sx={{
                    background: "lightblack",
                    "& input": { color: "black" },
                  }}
                />

                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                ></Box>
                <Box sx={{ justifyContent: "center", textAlign: "center" }}>
                  <Button
                    type="submit"
                    variant="contained"
                    sx={{
                      mt: 3,
                      mb: 3,
                      background: "#be1719",
                      color: "white",
                      "&:hover": { background: "white", color: "black" },
                      height: "43px",
                      borderRadius: "21px",
                      width: "140px",
                    }}
                  >
                    Sign In
                  </Button>
                </Box>
              </form>
            </Box>
          </Container>

          <Container
            sx={{
              background: "#be384c",
              height: isMobile ? "43vh" : "77vh",
              paddingTop: isMobile ? "7vh" : "13vh",
              textAlign: "center",
              borderTopRightRadius: isMobile ? "0px" : "34px",
              borderBottomRightRadius: isMobile ? "0px" : "34px",
              boxShadow: isMobile ? "none" : "5px 5px 10px #bb8484",
              borderTopLeftRadius: isMobile ? "0px" : "4px",
              borderBottomLeftRadius: isMobile ? "0px" : "4px",
            }}
          >
            <Typography
              component="h1"
              variant="h2"
              sx={{
                color: "white",
                fontWeight: "900",
                marginBottom: "17px",
                padding: "7px 7px",
                fontSize: isMobile ? "32px" : "4.5rem",
              }}
            >
              Bivab Developers
            </Typography>

            <Typography
              component="h6"
              variant="h6"
              sx={{ color: "white", fontWeight: "400" }}
            >
              Real estate builders & construction company in Bhubaneswar,
              Odisha.
            </Typography>
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default LoginRegistrationPage;
