import { Box } from '@mui/material';
import './App.css';
import Routing from './navigation/Routing';

function App() {
  
  return (
    <Box>
      <Routing/>
    </Box>
  );
}

export default App;
