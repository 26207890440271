import React from "react";
import { Grid, Card, CardContent, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { FaRegBuilding } from "react-icons/fa";
import { PiTargetFill } from "react-icons/pi";
import { GiEyeTarget } from "react-icons/gi";
import { RiContactsLine } from "react-icons/ri";

const cardsData = [
  { title: "Commercial", icon: <FaRegBuilding icon="Commercial" />, link: "/yashilaabout-bivab-commercial" },
  { title: "Mission", icon: <PiTargetFill icon="Mission" />, link: "/yashilaabout-mision" },
  { title: "Vision", icon: <GiEyeTarget icon="Vision" />, link: "/yashila-vision" },
  { title: "Contacts", icon: <RiContactsLine icon="Contacts" />, link: "/yashilacontact" },
];

const GridCards = () => {
  return (
    <Grid container spacing={2}>
      {cardsData.map((card, index) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
          <Link to={card.link} style={{ textDecoration: "none" }}>
            {" "}
            {/* Wrap each card in a Link component */}
            <Card
              sx={{ backgroundColor: "#00000014", boxShadow: 1 }}
            >
              <CardContent sx={{ padding: 2, display:'flex', alignItems:'center', justifyContent:'space-around' }}>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginBottom: 1, fontSize:'28px', fontWeight:'400', color:'#5f5f5f' }}
                >
                  {card.title}
                </Typography>
                <Typography
                  variant="h5"
                  component="div"
                  sx={{ marginBottom: 1, fontSize:'42px', fontWeight:'400', color:'#961718' }}
                >
                  {card.icon}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </Grid>
  );
};

export default GridCards;
