import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  useTheme,
  Grid,
  TextField,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import AboutMobile from "../../../components/reusable/mobile/AboutMobile";

export default function About() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add About
          </Typography>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ m: 5 }}>
              <Box
                sx={{
                  p: 3,
                  border: "1px solid",
                  borderRadius: 4,
                  borderColor: "divider",
                }}
              >
                <Typography variant="h6" align="center" sx={{ mb: 2 }}>
                  Add Details
                </Typography>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="ID"
                        label="ID"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="title"
                        label="Title"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        id="description"
                        label="Description"
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="inputGroupFile01"
                        label="Image"
                        fullWidth
                        type="file"
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12} sx={{ textAlign: "center" }}>
                      <Button type="submit" variant="contained" color="error">
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Box>

            <Box
              sx={{ display: "flex", justifyContent: "center", width: "39vw" }}
            >
              <Box
                sx={{
                  height: "90vh",
                  border: "1px solid",
                  padding: "0px 10px",
                  paddingTop: "57px",
                  borderRadius: "30px",
                  backgroundColor: colors.black[200],
                }}
              >
                <AboutMobile />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
