import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Modal,
  IconButton,
  TextField,
  Grid,
  FormGroup,
  Switch,
  FormControlLabel,
  styled,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { mockDataBivabBlog } from "../../data/mockData";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
const Homeaboutbivab = () => {
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(mockDataBivabBlog);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    imageName: "",
    postDate: "",
    heading: "",
    description: "",
    contents: [{ title: "", description: "" }],
  });

  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const handleAddModalOpen = () => {
    setIsAddModalOpen(true);
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    setIsEditModalOpen(true);
  };

  const closeModal = () => {
    setIsAddModalOpen(false);
    setIsEditModalOpen(false);
    setFormData({
      id: "",
      imageName: "",
      postDate: "",
      heading: "",
      description: "",
      contents: "",
    });
  };

  const deleteData = (id) => {
    const updatedData = filteredData.filter((item) => item.id !== id);
    setFilteredData(updatedData);
    closeModal();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    closeModal();
  };

  const handleSearch = (e) => {
    const searchText = e.target.value;
    setSearchText(searchText);
    const filtered = mockDataBivabBlog.filter(
      (item) =>
        item.postDate.toLowerCase().includes(searchText.toLowerCase()) ||
        item.heading.toLowerCase().includes(searchText.toLowerCase()) ||
        item.description.toLowerCase().includes(searchText.toLowerCase()) ||
        item.contents.toLowerCase().includes(searchText.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const columns = [
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`/assets/${row.imageName}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Post Date",
      selector: (row) => row.postDate,
      sortable: true,
    },
    {
      name: "heading",
      selector: (row) => row.heading,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Content",
      selector: (row) => row.contents,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <Box>
          <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <Edit />
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete />
          </Button>
        </Box>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Home About Bivab"
          subtitle="List of about bivab details"
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAddModalOpen}>
              ADD NEW DETAILS
            </Button>
            <Box
              sx={{ marginLeft: "20px", display: "flex", alignItems: "center" }}
            >
              <Typography variant="body1" sx={{ marginRight: "10px" }}>
                Search:
              </Typography>
              <TextField
                type="text"
                value={searchText}
                onChange={handleSearch}
              />
            </Box>
          </Box>
        </Box>
        <Modal open={isAddModalOpen} onClose={closeModal}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 600,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography variant="h3" component="h3">
                Add New Details
              </Typography>
              <IconButton
                onClick={closeModal}
                aria-label="Close"
                sx={{ position: "absolute", top: 0, right: 0 }}
              >
                <Close />
              </IconButton>
            </Box>
            <form onSubmit={(e) => handleSubmit(e, formData)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField id="postDate" label="Post Date" fullWidth />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="heading" label="Heading" fullWidth />
                </Grid>

                <Grid item xs={6}>
                  <TextField
                    id="imageName"
                    label="Image"
                    fullWidth
                    type="file"
                    InputLabelProps={{ shrink: true }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField id="description" label="Description" fullWidth />
                </Grid>
              </Grid>
              <Box
                sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
              >
                <Button variant="contained" onClick={closeModal} color="error">
                  CANCEL
                </Button>
                <Button type="submit" variant="contained" color="primary">
                  SUBMIT
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
        <Box sx={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
                search: true,
                searchPlaceholder: "Search...",
              }}
              onSearch={(newSearchText) => setSearchText(newSearchText)}
            />
          </Box>
        </Box>
      </Box>
      <Modal open={isEditModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5" component="h2">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={(e) => handleSubmit(e, formData)}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField id="postDate" label="Post Date" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField id="heading" label="Heading" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField id="description" label="Description" fullWidth />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  id="imageName"
                  label="Image"
                  fullWidth
                  type="file"
                  InputLabelProps={{ shrink: true }}
                />
              </Grid>
            </Grid>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" color="primary">
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};
export default Homeaboutbivab;
