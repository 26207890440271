import { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Box,
  useTheme,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import Header from "../../../components/reusable/Header";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";
import ProjectMobile from "../../../components/reusable/mobile/ProjectMobile";

export default function AddProject() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [domLoaded, setDomLoaded] = useState(false);
  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            <b>Projects</b>
          </Typography>
          <br />
          <br />
          <Box sx={{ display: "flex" }}>
            <Box sx={{ border: "2px soild black" }}>
              <Card variant="outlined" style={{ marginRight: "20px" }}>
                <CardContent style={{ border: "1px solid gray" }}>
                  <h6 class="card-title text-center">
                    <u>
                      <b>Add Details</b>
                    </u>
                  </h6>
                  <br />
                  <br />
                  <form className="row g-3 w-100">
                    <Grid container spacing={2}>
                      <Grid item xs={3}>
                        <TextField label="ID" id="ID" fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Type" id="propertyType" fullWidth />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          label="Property Location"
                          id="inputbhk"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label="Property Name"
                          id="propertyName"
                          fullWidth
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Status" id="status" fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Unit" id="unit" fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField label="Floors" id="floors" fullWidth />
                      </Grid>
                      <Grid item xs={3}>
                        <FormControl fullWidth>
                          <InputLabel id="inputGroupSelectLabel">
                            BHK
                          </InputLabel>
                          <Select
                            labelId="inputGroupSelectLabel"
                            id="inputGroupSelect"
                            defaultValue="1 BHK"
                            fullWidth
                          >
                            <MenuItem value="1 BHK">1 BHK</MenuItem>
                            <MenuItem value="2 BHK">2 BHK</MenuItem>
                            <MenuItem value="3 BHK">3 BHK</MenuItem>
                            <MenuItem value="4 BHK">4 BHK</MenuItem>
                          </Select>
                        </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="inputGroupFile01"
                          label="Profile"
                          fullWidth
                          type="file"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ multiple: true }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          id="inputGroupFile01"
                          label="PDF"
                          fullWidth
                          type="file"
                          InputLabelProps={{ shrink: true }}
                          inputProps={{ multiple: true }}
                        />
                      </Grid>
                      <Grid item xs={12} my={4}>
                        <Button variant="contained" color="error" fullWidth>
                          Create
                        </Button>
                      </Grid>
                    </Grid>
                  </form>
                </CardContent>
              </Card>
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "center", width: "50%" }}
            >
              <Box
                sx={{
                  height: "90vh",
                  border: "1px solid",
                  padding: "0px 10px",
                  paddingTop: "57px",
                  borderRadius: "30px",
                  backgroundColor: colors.black[200],
                }}
              >
                <ProjectMobile />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
    </Layout>
  );
}
