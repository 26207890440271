import React from "react";
import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { tokens } from "../../constants/theme";
import Popover from "@mui/material/Popover";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import { useNavigate } from "react-router-dom";

const Topbar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isSticky, setIsSticky] = React.useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSignout = () => {
    localStorage.removeItem('email');
    localStorage.removeItem('password');
    window.location.reload();
    navigate("/")
  };


  const handleScroll = () => {
    const offset = window.scrollY;
    setIsSticky(offset > 0);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <Box
      sx={{
        position: isSticky ? "fixed" : "relative",
        top: 0,
        left: 0,
        right: 0,
        zIndex: 1000,
        backgroundColor: "white", // Change to your desired background color
        boxShadow: isSticky ? "0px 2px 4px rgba(0, 0, 0, 0.1)" : "none",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          padding: "2px",
          marginRight:'20px',
          "& .pro-menu-item.active": {
            color: "#6870fa !important",
          },
        }}
      >
        {/* ICONS */}
        <Box sx={{ display: "flex" }}>
          <IconButton onClick={handleClick}>
            <PersonOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <Box marginTop="15px" marginBottom="10px" width="20vh">
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <img
                  alt="profile-user"
                  width="70px"
                  height="50px"
                  src="/assets/profile-logo.png"
                  style={{
                    cursor: "pointer",
                    borderRadius: "50%",
                    objectFit: "contain",
                  }}
                />
                <Typography
                  variant="h6"
                  color={colors.grey[100]}
                  fontWeight="bold"
                  sx={{ m: "10px 0 0 0", marginTop: "-5px" }}
                >
                  Bivab Dev.
                </Typography>
              </Box>
              <Box
                display="flex"
                flexDirection="column"
                textAlign="center"
                marginTop="7px"
              >
                {/* <MenuItem icon={<ModeEditOutlineOutlinedIcon />} text="EDIT" onClick={handleClose} /> */}

                <Typography
                  variant="h6"
                  color={colors.grey[100]}
                  fontWeight="400"
                  onClick={handleSignout}
                  sx={{
                    m: "10px 0 0 0",
                    marginTop: "-5px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginTop: "10px",
                    cursor: "pointer",
                  }}
                >
                  <ExitToAppOutlinedIcon sx={{ fontWeight: "400" }} />
                  <Typography
                    variant="h6"
                    color={colors.grey[100]}
                    fontWeight="400"
                    onClick={handleSignout}
                    sx={{ fontSize:"14px" }}
                  >
                    SIGN OUT
                  </Typography>
                </Typography>
              </Box>
            </Box>
          </Popover>
        </Box>
      </Box>
    </Box>
  );
};

const MenuItem = ({ icon, text, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <Box
      display="flex"
      alignItems="center"
      textAlign="center"
      justifyContent="center"
      color={colors.grey[100]}
      sx={{
        "&:hover": {
          color: colors.greenAccent[500],
        },
      }}
      onClick={onClick}
      style={{ cursor: "pointer" }}
    >
      {icon}
      <Typography
        variant="h6"
        fontWeight="400"
        sx={{ m: "10px 0 0 0", paddingLeft: "10px" }}
      >
        {text}
      </Typography>
    </Box>
  );
};

export default Topbar;
