import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/global/Layout";
import Header from "../../components/reusable/Header";
import { tokens } from "../../constants/theme";
import { GETNETWORK, PUTNETWORK, DELETENETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";

const YashilaGetintouchinfo = () => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  // FOR API SET DATA
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    location: "",
    email: "",
    primaryNumber: "",
    secondaryNumber: "",
  });

  // START API INTEGRATION
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.GETINTOUCH_GETALL);
    setData(response.data);
  };

  const editForm = async (id) => {
    const responseData = await GETNETWORK(`${ApiUrl.GETINTOUCH_GETBYID}/${id}`);
    if (responseData.status) {
      setFormData({
        ...responseData.data,
      });
      setSelectedItemId(id);
      setIsModalOpen(true);
    }
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: "",
      location: "",
      email: "",
      primaryNumber: "",
      secondaryNumber: "",
    });
  };

  const deleteData = async (id) => {
    await deleteDataById(id);
  };

  const deleteDataById = async (id) => {
    const responseData = await DELETENETWORK(
      `${ApiUrl.GETINTOUCH_DELETE}/${id}`
    );
    fetchData();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        `${ApiUrl.GETINTOUCH_PUT}/${selectedItemId}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formData),
        }
      );
      if (!response.ok) {
        throw new Error("Failed to update data");
      }
      fetchData();
      closeModal();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };
  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Location",
      selector: (row) => row.location,
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
    },
    {
      name: "Primary No.",
      selector: (row) => row.primaryNumber,
      sortable: true,
    },
    {
      name: "Secondary No.",
      selector: (row) => row.secondaryNumber,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <div>
          <IconButton onClick={() => editForm(row.id)}>
            <Edit style={{color:'green'}}/>
          </IconButton>
          <IconButton onClick={() => deleteData(row.id)}>
            <Delete style={{color:'red'}}/>
          </IconButton>
        </div>
      ),
      allowOverflow: true,
      button: true,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="Yashila Get In Touch(INFO)"
          subtitle="You can add or modify details"
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              onClick={() =>
                navigate("/yashilagetintouchinfo/addyashilagetintouchinfo")
              }
              sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}
            >
              ADD NEW DETAILS
            </Button>
          </div>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box width="75vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>

          {/* <Box
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              height: "100%",
              width: "350px",
              boxShadow: "0px 0px 10px 2px black",
              borderRadius: "5px",
            }}
          >
            <Box
              style={{
                flex: "1 0 50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <img
                src="/assets/git.png"
                alt="Your Image"
                style={{ maxWidth: "100%", height: "240px" }}
              />
            </Box>
            <br />
            <Typography
              variant="h5"
              style={{ marginLeft: "2px", color: "black" }}
            >
              This is the get in touch
            </Typography>
          </Box> */}
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 800,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <TextField
                label="ID"
                variant="outlined"
                name="id"
                type="text"
                value={formData.id}
                disabled
                fullWidth
                margin="normal"
              />
              <TextField
                label="Location"
                variant="outlined"
                name="location"
                type="text"
                value={formData.location}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <TextField
                label="Email"
                variant="outlined"
                name="email"
                type="text"
                value={formData.email}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Primary No."
                variant="outlined"
                name="primaryNumber"
                type="text"
                value={formData.primaryNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: 2,
                marginBottom: 2,
              }}
            >
              <TextField
                label="Secondary No."
                variant="outlined"
                name="secondaryNumber"
                type="text"
                value={formData.secondaryNumber}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default YashilaGetintouchinfo;
