import { Close, Delete, Edit } from "@mui/icons-material";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Modal,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import Layout from "../../components/global/Layout";
import Header from "../../components/reusable/Header";
import { tokens } from "../../constants/theme";
import { mockDataYashilaAboutOwners } from "../../data/mockData";
import { DELETENETWORK, GETNETWORK } from "../../utils/network";
import ApiUrl from "../../utils/url";
const YashilaAboutOwners = () => {
  const navigate = useNavigate();

  const [filteredData, setFilteredData] = useState(mockDataYashilaAboutOwners);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({
    id: "",
    image: "",
    name: "",
    designation: "",
    about: "",
  });

  const [editId, setEditId] = useState("");
  const [editName, setEditName] = useState("");
  const [editDesignation, setEditDesignation] = useState("");
  const [editAbout, setEditAbout] = useState("");
  const [editImage, setEditImage] = useState(null);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await GETNETWORK(ApiUrl.OWNER_GETALL);
    console.log("1stresponse", response);
    setData(response.data);
  };

  const handelUpdate = async (e) => {
    e.preventDefault();
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", editName);
      formDataToSend.append("designation", editDesignation);
      formDataToSend.append("about", editAbout);
      formDataToSend.append("image", editImage);

      const response = await fetch(
        `${ApiUrl.OWNER_UPDATE}${editId}`,
        {
          method: "PUT",
          body: formDataToSend,
        }
      );

      if (!response.status) {
        throw new Error("Failed to add team");
      }

      fetchData();
      closeModal();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditModalOpen = (id) => {
    setSelectedItemId(id);
    getItemByid(id);
    setIsModalOpen(true);
  };

  const getItemByid = async (id) => {
    const response = await GETNETWORK(
      `${ApiUrl.BIVABDEV_KNOWMORE_GETBYID}${id}`
    );
    if (response.status) {
      setEditId(response.data.id);
      setEditName(response.data.name);
      setEditDesignation(response.data.designation);
    }
  };


  const deleteData = async (id) => {
    await deleteDataByid(id);
  };

  const deleteDataByid = async (id) => {
    await DELETENETWORK(`${ApiUrl.OWNER_DELETE}/${id}`);
    fetchData();
    console.log("Item with ID:", id, "has been deleted.");
  };

  const handleAdd = () => {
    navigate("/yashilaabout-bivab-owner/addyashilaabout-bivab-owner");
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: "",
      image: "",
      name: "",
      designation: "",
      about: "",
    });
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`${ApiUrl.ImageHostURl}${row.imageName}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Designation",
      selector: (row) => row.designation,
      sortable: true,
    },
    {
      name: "About",
      selector: (row) => row.about,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <div>
          <Button
            onClick={() => handleEditModalOpen(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </div>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="About Bivab Owners"
          subtitle="You can add or modify bivab ownersdetails"
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAdd} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
            <Box sx={{ display: "flex", alignItems: "center" }}></Box>
          </div>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box width="50vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={data}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              height: "100%",
              width: "350px",
              boxShadow: "0px 0px 10px 2px black",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                flex: "1 0 50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <img
                src="/assets/own.jpeg"
                alt="Your Image"
                style={{ maxWidth: "100%", height: "240px" }}
              />
            </div>
            <br />
            <Typography
              variant="h5"
              style={{ marginLeft: "2px", color: "black" }}
            >
              This is the about bivab page.
            </Typography>
          </div>
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handelUpdate}>
            <Grid item xs={12} mt={2}>
              <TextField
                id="Name"
                label="name"
                variant="outlined"
                fullWidth
                value={editName}
                onChange={(e) => setEditName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <TextField
                id="Designation"
                label="designation"
                variant="outlined"
                fullWidth
                value={editDesignation}
                onChange={(e) => setEditDesignation(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <TextField
                id="About"
                label="about"
                variant="outlined"
                fullWidth
                value={editAbout}
                onChange={(e) => setEditAbout(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} mt={2}>
              <TextField
                label="Image"
                id="inputGroupFile01"
                type="file"
                fullWidth
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setEditImage(e.target.files[0])}
              />
            </Grid>

            {formData.image && (
              <Typography variant="body1">{formData.image.name}</Typography>
            )}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default YashilaAboutOwners;
