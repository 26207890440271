import * as React from 'react';
import { useRef } from 'react';
import { Box, Button, Typography, useTheme, Popover } from "@mui/material";
import { tokens } from "../../../constants/theme";
import { mockDataProject } from '../../../data/mockData';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import HomeIcon from '@mui/icons-material/Home';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ElectricMeterOutlinedIcon from '@mui/icons-material/ElectricMeterOutlined';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import GiteIcon from '@mui/icons-material/Gite';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';

{/* Tab Functions and Logics */}
function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };
  
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  {/* End */}

const ProjectMobile = () => {

    const contentRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    {/* Tab Hooks & Functionalities */}
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    {/* End */}
    

  return (
    <Box sx={{height:'76.5vh', width:'22.22vw', border:`3px solid ${colors.black[100]}`, borderRadius:'8px', backgroundColor:colors.white[100]}}>
        <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
            <Box ref={contentRef} sx={{height: '800px', position: 'relative' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="2px 11px" marginBottom="0px" sx={{backgroundColor: colors.blue[100] }}>
                        <Typography
                                variant="h4"
                                color={colors.white[100]}
                                fontWeight="bold"
                                sx={{ m: "10px 0 0 0", marginTop:'-5px'}}
                            >
                            Gallery
                        </Typography>
                        <img
                                alt="profile-user"
                                width="80px"
                                height="50px"
                                src={`/assets/profile-logo.png`}
                                style={{ cursor: "pointer" , filter:'grayscale(1) contrast(0) brightness(1000)'}}
                            />
                    </Box>

                    {/* Tab Designs */}
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs sx={{ 
                                    "& .css-heg063-MuiTabs-flexContainer": {
                                        justifyContent:'center',
                                        backgroundColor: colors.blue[100],
                                        color: colors.white[100],
                                        fontWeight: '700'
                                    },
                                    }} 
                                  value={value}
                                  onChange={handleChange}
                                  aria-label="basic tabs example"
                                  indicatorColor={colors.white[100]}
                                  textColor={colors.white[100]}>

                                <Tab label="All" {...a11yProps(0)} />

                                <Tab label="Commercisl" {...a11yProps(1)} />

                                <Tab label="Residental" {...a11yProps(2)} />

                            </Tabs>
                        </Box>

                        <CustomTabPanel value={value} index={0}>

                            {mockDataProject.map((item) => (

                              item.type === 'Commercial' || item.type === 'Residential' ? (

                              <Box key={item.id} display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop="-42px">
                                <Button variant="contained" 
                                  sx={{ color: colors.white[100], 
                                        backgroundColor: colors.greenAccent[500], 
                                        borderRadius: '18px',
                                        position:'relative',
                                        top:'39px',
                                        left:'-84px'
                                        }} 
                                  >
                                    Sold Out
                                  </Button>
                                <img
                                    alt="profile-user"
                                    width="270px"
                                    height="200px"
                                    src={`/assets/${item.profile}`}
                                    style={{ cursor: "pointer"}}
                                />

                                {/* PopOver Designs */}

                                <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                      <div>
                                        <Box {...bindTrigger(popupState)}
                                          sx={{display:'flex', 
                                                flexDirection:'column', 
                                                position:'relative', 
                                                top:'-72px',
                                                backgroundColor: colors.white[100],
                                                borderRadius:'10px',
                                                padding:'10px 8px',
                                                cursor:'pointer',}}>
                                            <Box sx={{display:'flex'}}>
                                                <HomeIcon/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <LocationOnIcon/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.location}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {/* Popover container */}
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                          sx={{
                                            "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                                              opacity: 1,
                                              transform: 'none',
                                              transition: 'opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 163ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                              top: '349px',
                                              left: '1098px',
                                              transformOrigin: '86.5px 0px',
                                              padding:'15px',
                                              borderRadius:'10px',
                                              boxShadow:'0px 0px 15px 0px rgb(0 0 0)'
                                            }
                                          }}
                                        >
                                          <Typography
                                            variant="h5"
                                            fontWeight="700"
                                            sx={{marginBottom:'5px'}}
                                            >
                                                Property Details
                                          </Typography>
                                          <Box sx={{display:'flex'}}>
                                                <HomeIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <LocationOnIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.location}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <ElectricMeterOutlinedIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.unit}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <CorporateFareIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.floors}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <GiteIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.bhk}
                                                </Typography>
                                            </Box>
                                        </Popover>

                                      </div>
                                    )}
                                </PopupState>
                                
                            </Box>
                                  ) : null
                              
                            ))}

                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>

                          {/* PopOver Designs for Commercial */}

                            {mockDataProject.map((item) => (

                              item.type === 'Commercial' ? (
                              <Box key={item.id} display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop="-42px">
                                <Button variant="contained" 
                                  sx={{ color: colors.white[100], 
                                        backgroundColor: colors.greenAccent[500], 
                                        borderRadius: '18px',
                                        position:'relative',
                                        top:'39px',
                                        left:'-84px'
                                        }} 
                                  >
                                    Sold Out
                                  </Button>
                                <img
                                    alt="profile-user"
                                    width="270px"
                                    height="200px"
                                    src={`/assets/${item.profile}`}
                                    style={{ cursor: "pointer"}}
                                />

                                {/* PopOver Designs */}

                                <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                      <div>
                                        <Box {...bindTrigger(popupState)}
                                          sx={{display:'flex', 
                                                flexDirection:'column', 
                                                position:'relative', 
                                                top:'-72px',
                                                backgroundColor: colors.white[100],
                                                borderRadius:'10px',
                                                padding:'10px 8px',
                                                cursor:'pointer',}}>
                                            <Box sx={{display:'flex'}}>
                                                <HomeIcon/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <LocationOnIcon/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.location}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {/* Popover container */}
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                          sx={{
                                            "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                                              opacity: 1,
                                              transform: 'none',
                                              transition: 'opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 163ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                              top: '349px',
                                              left: '1098px',
                                              transformOrigin: '86.5px 0px',
                                              padding:'15px',
                                              borderRadius:'10px',
                                              boxShadow:'0px 0px 15px 0px rgb(0 0 0)'
                                            }
                                          }}
                                        >
                                          <Typography
                                            variant="h5"
                                            fontWeight="700"
                                            sx={{marginBottom:'5px'}}
                                            >
                                                Property Details
                                          </Typography>
                                          <Box sx={{display:'flex'}}>
                                                <HomeIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <LocationOnIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.location}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <ElectricMeterOutlinedIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.unit}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <CorporateFareIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.floors}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <GiteIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.bhk}
                                                </Typography>
                                            </Box>
                                        </Popover>

                                      </div>
                                    )}
                                </PopupState>
                                
                            </Box>
                                  ) : null
                                
                            ))}

                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={2}>

                          {/* PopOver Designs for Residential */}

                            {mockDataProject.map((item) => (

                              item.type === 'Residential' ? (
                              <Box key={item.id} display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop="-42px">
                                <Button variant="contained" 
                                  sx={{ color: colors.white[100], 
                                        backgroundColor: colors.greenAccent[500], 
                                        borderRadius: '18px',
                                        position:'relative',
                                        top:'39px',
                                        left:'-84px'
                                        }} 
                                  >
                                    Sold Out
                                  </Button>
                                <img
                                    alt="profile-user"
                                    width="270px"
                                    height="200px"
                                    src={`/assets/${item.profile}`}
                                    style={{ cursor: "pointer"}}
                                />

                                {/* PopOver Designs */}

                                <PopupState variant="popover" popupId="demo-popup-popover">
                                    {(popupState) => (
                                      <div>
                                        <Box {...bindTrigger(popupState)}
                                          sx={{display:'flex', 
                                                flexDirection:'column', 
                                                position:'relative', 
                                                top:'-72px',
                                                backgroundColor: colors.white[100],
                                                borderRadius:'10px',
                                                padding:'10px 8px',
                                                cursor:'pointer',}}>
                                            <Box sx={{display:'flex'}}>
                                                <HomeIcon/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <LocationOnIcon/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.location}
                                                </Typography>
                                            </Box>
                                        </Box>

                                        {/* Popover container */}
                                        <Popover
                                          {...bindPopover(popupState)}
                                          anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                          }}
                                          transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                          }}
                                          sx={{
                                            "& .css-3bmhjh-MuiPaper-root-MuiPopover-paper": {
                                              opacity: 1,
                                              transform: 'none',
                                              transition: 'opacity 245ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, transform 163ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                                              top: '349px',
                                              left: '1098px',
                                              transformOrigin: '86.5px 0px',
                                              padding:'15px',
                                              borderRadius:'10px',
                                              boxShadow:'0px 0px 15px 0px rgb(0 0 0)'
                                            }
                                          }}
                                        >
                                          <Typography
                                            variant="h5"
                                            fontWeight="700"
                                            sx={{marginBottom:'5px'}}
                                            >
                                                Property Details
                                          </Typography>
                                          <Box sx={{display:'flex'}}>
                                                <HomeIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.name}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <LocationOnIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.location}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <ElectricMeterOutlinedIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.unit}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <CorporateFareIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.floors}
                                                </Typography>
                                            </Box>
                                            <Box sx={{display:'flex'}}>
                                                <GiteIcon sx={{marginRight:'5px'}}/>
                                                <Typography
                                                variant="h6"
                                                fontWeight="400"
                                                >
                                                    {item.bhk}
                                                </Typography>
                                            </Box>
                                        </Popover>

                                      </div>
                                    )}
                                </PopupState>
                                
                            </Box>
                                  ) : null
                            ))}

                        </CustomTabPanel>
                    </Box>
                   

                <Box display="flex" justifyContent="center" alignItems="center" marginTop="32px" height="70px" sx={{backgroundColor: colors.black[100]}}>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        sx={{ color: colors.white[100], marginRight:'15px'}}
                        >
                          Also contact us in
                    </Typography>
                    <WhatsAppIcon sx={{color:'white', backgroundColor:'#21d921', width:'36px', height: '38px', borderRadius:'26px'}}/>
                </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default ProjectMobile