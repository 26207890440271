import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Link,
  Modal,
  IconButton,
  TextField,
  Grid,
} from "@mui/material";
import DataTable from "react-data-table-component";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { tokens } from "../../constants/theme";
import { mockDataYashilaAboutBivab } from "../../data/mockData";
import { useTheme } from "@mui/material";
import Header from "../../components/reusable/Header";
import Layout from "../../components/global/Layout";
import { Edit, Delete, Close } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

const YashilaAboutBivab = () => {
  const navigate = useNavigate();
  const [filteredData, setFilteredData] = useState(mockDataYashilaAboutBivab);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [formData, setFormData] = useState({
    id: "",
    image: "",
    title: "",
    description: "",
  });

  const handleAdd = () => {
    navigate("/yashilaabout-bivab/addyashilaabout-bivab");
  };

  const editForm = (id) => {
    const selectedItem = mockDataYashilaAboutBivab.find(
      (item) => item.id === id
    );
    setSelectedItemId(id);
    setFormData(selectedItem);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setFormData({
      id: "",
      image: "",
      title: "",
      description: "",
    });
  };

  const deleteData = (id) => {
    const updatedData = filteredData.filter((item) => item.id !== id);
    setFilteredData(updatedData);
    console.log("Item with ID:", id, "has been deleted.");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted with data:", formData);
    closeModal();
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setFormData((prevState) => ({
      ...prevState,
      image: file,
    }));
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const customStyles = {
    headRow: {
      style: {
        backgroundColor: "#dc3545",
      },
    },
    actionCell: {
      style: {
        padding: "5px",
        borderRadius: "5px",
      },
    },
    editButton: {
      backgroundColor: "lightgray",
      marginRight: "5px",
    },
    deleteButton: {
      backgroundColor: "lightgray",
    },
  };

  const columns = [
    {
      name: "ID",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Image",
      cell: (row) => (
        <img
          src={`/assets/${row.image}`}
          alt="Product"
          style={{ width: "50px", height: "50px", borderRadius: "50%" }}
        />
      ),
      sortable: true,
    },
    {
      name: "Title 1",
      selector: (row) => row.title1,
      sortable: true,
    },
    {
      name: "Title 2",
      selector: (row) => row.title2,
      sortable: true,
    },
    {
      name: "Description 1",
      selector: (row) => row.description1,
      sortable: true,
    },
    {
      name: "Description 2",
      selector: (row) => row.description2,
      sortable: true,
    },
    {
      name: "Access Level",
      cell: (row) => (
        <div>
          <Button
            onClick={() => editForm(row.id)}
            style={customStyles.editButton}
          >
            <Edit style={{color:'green'}}/>
          </Button>
          <Button
            onClick={() => deleteData(row.id)}
            style={customStyles.deleteButton}
          >
            <Delete style={{color:'red'}}/>
          </Button>
        </div>
      ),
      style: customStyles.actionCell.style,
    },
  ];

  return (
    <Layout>
      <Box m="20px">
        <Header
          title="About Bivab"
          subtitle="You can add or modify bivab details"
        />
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button variant="contained" onClick={handleAdd} sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
              ADD NEW DETAILS
            </Button>
            <Box sx={{ display: "flex", alignItems: "center" }}></Box>
          </div>
        </Box>
        <Box style={{ display: "flex" }}>
          <Box width="50vw" overflow="hidden">
            <DataTable
              columns={columns}
              data={filteredData}
              pagination
              paginationPerPage={5}
              paginationRowsPerPageOptions={[5, 10, 20]}
              striped
              highlightOnHover
              responsive
              fixedHeader
              customStyles={customStyles}
              paginationComponentOptions={{
                rowsPerPageText: "Rows per page:",
                rangeSeparatorText: "of",
                noRowsPerPage: false,
                selectAllRowsItem: false,
                selectAllRowsItemText: "All",
              }}
            />
          </Box>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "20px",
              height: "100%",
              width: "350px",
              boxShadow: "0px 0px 10px 2px black",
              borderRadius: "5px",
            }}
          >
            <div
              style={{
                flex: "1 0 50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "0px",
              }}
            >
              <img
                src="/assets/bivab.jpeg"
                alt="Your Image"
                style={{ maxWidth: "100%", height: "240px" }}
              />
            </div>
            <br />
            <Typography
              variant="h5"
              style={{ marginLeft: "2px", color: "black" }}
            >
              This is the about bivab page.
            </Typography>
          </div>
        </Box>
      </Box>
      <Modal open={isModalOpen} onClose={closeModal}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 600,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <Typography variant="h5">
              Edit Form
            </Typography>
            <IconButton
              onClick={closeModal}
              aria-label="Close"
              sx={{ position: "absolute", top: 0, right: 0 }}
            >
              <Close />
            </IconButton>
          </Box>
          <form onSubmit={handleSubmit}>
            <Grid item xs={12} mt={2}>
              <TextField id="ID" label="id" variant="outlined" fullWidth />
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                label="Image"
                id="inputGroupFile01"
                type="file"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                id="Title 1"
                label="title1"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                id="Title 2"
                label="title2"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                id="Description 1"
                label="description1"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12} mt={2}>
              <TextField
                id="Description 2"
                label="description2"
                variant="outlined"
                fullWidth
              />
            </Grid>
            {formData.image && (
              <Typography variant="body1">{formData.image.name}</Typography>
            )}
            <Box
              sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}
            >
              <Button variant="contained" onClick={closeModal} color="error">
                Cancel
              </Button>
              <Button type="submit" variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }}>
                Submit
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Layout>
  );
};

export default YashilaAboutBivab;
