import React, { useState } from 'react';
import { Box, Typography, Modal, IconButton, TextField, Grid, Card, CardContent, CardActions,Button } from '@mui/material';
import Header from '../../components/reusable/Header';
import Layout from '../../components/global/Layout';
import { useNavigate } from 'react-router-dom';
import { Edit, Delete, Close } from '@mui/icons-material';

const YashilaAbout = () => {
  const navigate = useNavigate();

  return (
    <Layout>
      <Box m="20px">
        <Header title="Yashila About Page" />
        <br/>
        <br/>
        <Grid container spacing={2}>
          {/* <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" sx={{fontWeight:'bold'}}>
                  About Bivab
                </Typography>
                <Typography color="textSecondary">
                  Add details about Bivab
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="secondary" onClick={() => navigate('/yashilaabout-bivab')}>Click Here</Button>
              </CardActions>
            </Card>
          </Grid> */}
          {/* <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5" component="h2" sx={{fontWeight:'bold'}}>
                  About Bivab Owner
                </Typography>
                <Typography color="textSecondary">
                  Add details about Bivab owner
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" color="secondary" onClick={() => navigate('/yashilaabout-bivab-owner')}>Click Here</Button>
              </CardActions>
            </Card>
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <Card>
              <CardContent>
                <Typography variant="h5">
                  About Bivab Commercial
                </Typography>
                <Typography color="textSecondary">
                  Add details about Bivab commercial
                </Typography>
              </CardContent>
              <CardActions>
                <Button variant="contained" sx={{
                backgroundColor: "black",
                color: "white",
                "&:hover": {
                  backgroundColor: "black",
                  color: "white",
                },
              }} onClick={() => navigate('/yashilaabout-bivab-commercial')}>Click Here</Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

export default YashilaAbout;
