import { useEffect, useState } from "react";
import {
  Box,
  useTheme,
  Typography,
  TextField,
  Button,
  Grid,
} from "@mui/material";
import { tokens } from "../../../constants/theme";
import Layout from "../../../components/global/Layout";

export default function Contact() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [domLoaded, setDomLoaded] = useState(false);

  useEffect(() => {
    setDomLoaded(true);
  }, []);

  return (
    <Layout>
      {domLoaded && (
        <Box m="20px">
          <Typography variant="h5" sx={{ fontWeight: "bold" }}>
            Add Contact
          </Typography>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            spacing={3}
          >
            <Grid item xs={12} md={6}>
              <Box sx={{ p: 2, border: "1px solid gray", width: "40vw" }}>
                <Typography
                  variant="h6"
                  align="center"
                  sx={{ fontWeight: "bold" }}
                >
                  Add Details
                </Typography>
                <form>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        id="ID"
                        label="ID"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="name"
                        label="Name"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="email"
                        label="Email"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="phone"
                        label="Phone"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="message"
                        label="Message"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        id="question"
                        label="Question"
                        variant="outlined"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} my={4}>
                      <Button variant="contained" color="error" fullWidth>
                        Create
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Layout>
  );
}
