import React,{ useRef,useState } from 'react';
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../../../constants/theme";
import { mockDataEnquiry } from '../../../data/mockData';


import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const EnquiryMobile = () => {

    const [selectValues, setSelectValues] = useState({});

    const handleChange = (event, itemId) => {
        const { value } = event.target;

        // Update the state for the specific Select
        setSelectValues((prevValues) => ({
        ...prevValues,
        [itemId]: value,
        }));
    };

    const contentRef = useRef(null);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);


  return (
    <Box sx={{height:'76.5vh', width:'22.22vw', border:`3px solid ${colors.black[100]}`, borderRadius:'8px', backgroundColor:colors.white[100]}}>
        <Box sx={{ height: '100%', width: '100%', overflow: 'auto' }}>
            <Box ref={contentRef} sx={{height: '800px', position: 'relative' }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" padding="2px 11px" marginBottom="3px" sx={{backgroundColor: colors.blue[100] }}>
                        <Typography
                                variant="h4"
                                color={colors.white[100]}
                                fontWeight="bold"
                                sx={{ m: "10px 0 0 0", marginTop:'-5px'}}
                            >
                            Enquiry
                        </Typography>
                        <img
                                alt="profile-user"
                                width="80px"
                                height="50px"
                                src={`/assets/profile-logo.png`}
                                style={{ cursor: "pointer" , filter:'grayscale(1) contrast(0) brightness(1000)'}}
                            />
                    </Box>

                    {/* Enquiry start */}

                        {mockDataEnquiry.map((item) => (
                            <Box key={item.id} sx={{display:'flex',flexDirection:'column', alignItems:'center', justifyContent:'center'}}>
                                    <Typography
                                    variant="h6"
                                    color={colors.grey[100]}
                                    fontWeight="bold"
                                    sx={{ m: "10px 0 0 0", padding:'6px 20px'}}
                                        >
                                        {item.question}
                                    </Typography>
                                    <FormControl sx={{ m: 1, width: 286 }} size="small">
                                        <InputLabel id={`demo-select-small-label-${item.id}`}>Select One</InputLabel>
                                        <Select
                                        labelId={`demo-select-small-label-${item.id}`}
                                        id={`demo-select-small-${item.id}`}
                                        value={selectValues[item.id] || ''}
                                        label="Select One"
                                        onChange={(event) => handleChange(event, item.id)}
                                        >
                                        <MenuItem value="">
                                            <em>None</em>
                                        </MenuItem>
                                        <MenuItem value={item.option1}>{item.option1}</MenuItem>
                                        <MenuItem value={item.option2}>{item.option2}</MenuItem>
                                        <MenuItem value={item.option3}>{item.option3}</MenuItem>
                                        <MenuItem value={item.option4}>{item.option4}</MenuItem>
                                        </Select>
                                    </FormControl>
                            </Box>
                        ))}

                    {/* Enquiry end */}

                    <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" marginTop="17px" paddingBottom="15px">
                        <Button variant="contained" sx={{ color: colors.white[100], backgroundColor: colors.greenAccent[500], borderRadius: '18px' }} >Submit</Button>
                        <Typography
                                variant="h6"
                                color={colors.grey[100]}
                                fontWeight="bold"
                                sx={{ m: "4px 0 4px 0"}}
                            >
                            Or
                        </Typography>
                        <Button variant="contained" sx={{ color: colors.white[100], backgroundColor: colors.greenAccent[500], borderRadius: '18px' }} >Save & Draft</Button>
                    </Box>
            </Box>
        </Box>
    </Box>
  )
}

export default EnquiryMobile